<template>
    <div style="width: 70px;display:inline-block;">
        <!--添加按钮和模态框-->
        
        <!-- <a-button size="small" type="primary" style="display:inline-block;">
            {{modalBtn}}
            
           
        </a-button> -->
        <a-modal
                :visible="visible"
                :title= "modelTitle"
                okText='确认'
                cancel-text="取消"
                @cancel="handleCancel"
                @ok="handleOk"
        >
            <!--表单 并将表单的值绑定到this.from-->
            <a-form layout='vertical' :form="form">
                <!--每一项元素-->
                <a-form-item label='用户名'>
                    <a-input
                            v-decorator="[
                                      'username',
                                      {
                                        rules: [{ required: true, message: '请填写登录用户名!' }],
                                      }
                                ]"
                    />
                </a-form-item>
                <a-form-item label='手机号'>
                    <a-input
                            
                            v-decorator="[
                                      'phoneNum',
                                      {
                                        rules: [{ required: true, message: '请填写手机号!' }],
                                      }
                                ]"
                    />
                </a-form-item>
                <a-form-item label="状态">
                    <a-radio-group
                            v-decorator="[
                                  'status',
                                      {
                                        initialValue: '1',
                                      }
                                ]"
                    >
                        <a-radio value='1'>启用</a-radio>
                        <a-radio value='0'>禁用</a-radio>
                    </a-radio-group>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
    export default {
        props: {
		
            modalBtn: {
                type: String,
                default: "添加",
            },
            edit:{
                type:Function
            },
            ids: {
                type: String,
                default: "0",
            },

	    },
        //el 创建前
        beforeCreate() {
            //创建表单
            this.form = this.$form.createForm(this, { name: 'form_in_modal' });
        },
        data() {
            return {
                visible: false,
                //模态对话框标题
                modelTitle: '添加会员',
            };
        },
        methods: {
            // getParentData(){
            //     var data = [];
            //     this.getdata(); // 父组件传过来的方法在这里调用
            //     this.setdata(data);
            // },
            //显示模态框
            showModal() {
                this.visible = true;
            },
            //关闭模态框
            handleCancel() {
                this.visible = false;
            },
            //点击确认
            handleOk() {
                const form = this.form;
                form.validateFields((err, values) => {
                    if (err) {
                        return;
                    }
                    console.log('form 表单内容: ', values);
                    form.resetFields();
                    this.visible = false;
                });
            },
           
        },
       mounted(){
         
        }
    };
</script>
