<template>
  <div class="orderStatist">

    <!-- 搜索条件 -->
    <div class="queryTop">
      
     <!-- <a-select default-value="设备"  placeholder="设备" v-model="searchInfo.equipment"  class="memberpx">
        <a-select-option v-for="item in equipmentData" :value="item.value" :key="item.value">
          {{item.name}}
        </a-select-option>
      </a-select> -->

      <a-range-picker @change="timeChange" style="margin-right:20px;width: 24%" :locale="locale" :value="createValue"
      :show-time="{ hideDisabledOptions: true, defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')], }" format="YYYY-MM-DD"/> 
      <a-button type="primary" icon="search" @click="seach">搜索</a-button>
      <a-button icon="close" style="margin-left:1%;margin-right:1%;" @click="clearSeach">清空搜索</a-button>
      <a-icon type="question-circle" @mouseenter="clearsClick" @mouseleave="clearleave" style="font-size:20px;cursor: pointer;color:#888;"/>
      <div class="countryInt areaInt" v-if="clearsFlag" style="left: 40%;top: 190px;width: 500px;">
         该列表统计了每一种套餐的订单数和订单金额，可以直观的看到每日每一种套餐的收益情况和订单量。
          
      </div>
    </div>


    <!-- 搜索内容列表 -->
    <div class="queryContent">

         <!-- 列表内容 -->
  <a-table
    :columns="columns" :pagination="false"
    :data-source="dataList"
    bordered
    size="middle"
    
  />

    <!-- 分页 -->
    <div class="pagein">
        <a-pagination v-model="pages" :total="total" :page-size="rows" show-less-items
            :show-total="total => `共${total}条数据`" @change="onChangePage(pages)" />
    </div>
    </div>
  </div>
</template>

<script>
function deepMerge(obj1, obj2) {
    let key;
    for (key in obj2) {
        // 如果target(也就是obj1[key])存在，且是对象的话再去调用deepMerge，否则就是obj1[key]里面没这个对象，需要与obj2[key]合并
        obj1[key] =
        obj1[key] && obj1[key].toString() === "[object Object]"
            ? deepMerge(obj1[key], obj2[key])
            : (obj1[key] = obj2[key]);
    }
    console.log(obj1)
    return obj1;
}
import moment from 'moment';
import common from '@/assets/js/Common'
import API from '@/assets/js/Api'
import Modals from '../../components/Modal.vue'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
moment.locale('zh-cn')
export default {
  name: 'Member',
  components:{
   Modals
  },
  data() {
    return {
     dataList:[],
     columns:[ ],
      visible: false,    //编辑flag
      ModalText: 'Content of the modal',
      delectVisible: false,   //删除flag
      confirmLoading: false,
      idMember: 0,
      countryData:[],
      oemData:[{name: 'OEM', value: 'OEM'}],
      equipmentData:[
        {name: 'Android', value: 'Android'},
        {name: 'IOS', value: 'IOS'},
        {name: 'PC', value: 'PC'},

      ],

      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      pType: '渠道类型',
      searchInfo: {
        equipment: undefined,
        startTime:'',
        endTime:''
      },
      dateTime:[], //时间段
      locale,


      editingKey: '',
      createValue: [],
      pages:1,
      rows:10,
      total:10,
      tableTile:[],
      clearsFlag: false,

    };
  },

  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },

  beforeCreate() {
          //创建表单
          this.form = this.$form.createForm(this, { name: 'form_in_modal' });
  },
  
    methods: {
      moment,
      clearsClick(){
        this.clearsFlag = true;
      },
      clearleave(){
        this.clearsFlag = false;
      },
      // 合并对象
      deepMerge(obj1, obj2) {
            let key;
            for (key in obj2) {
                // 如果target(也就是obj1[key])存在，且是对象的话再去调用deepMerge，否则就是obj1[key]里面没这个对象，需要与obj2[key]合并
                obj1[key] =
                obj1[key] && obj1[key].toString() === "[object Object]"
                    ? deepMerge(obj1[key], obj2[key])
                    : (obj1[key] = obj2[key]);
            }
            this.tableTile = obj1;
            console.log(this.tableTile)
        },
       //查询国家设置
    seach(){
       let opts = {
              url: API.segmentCount,
              data: {
               
                start: this.searchInfo.startTime,
                end: this.searchInfo.endTime,
                page:this.pages
              },       
        }
        
       
        
        var arrChildCount = [];
        var arrNew = []
        common.query(opts).then(res=>{
         
        this.total = res.total
        let pakarr = res.order_pak_arr
        let moneyArr = res.money_pak_arr;
        let tableList = res.list;
     
        var arr = []; //定义数组
        var arrTwo = [];
        
       
        for (var i in pakarr) {
            arr.push({title:pakarr[i],id:i,index: i,key: i}); 
        }
        arr.unshift({title:'总订单数',id:'total_order',index: 'total_order'})
        
        for (var i in moneyArr) {
            arrTwo.push({title:moneyArr[i],id:i,index:i,key: i}); 
        }
        
        arrTwo.unshift({title:'总金额数',id:'total_money',index: 'total_money'})

       
        arr.forEach(function(v,i){
          arrChildCount.push({title:v.title,dataIndex: v.index,key: v.key,align: 'center'})
        })
        arrTwo.forEach(function(v,i){
          arrNew.push({title:v.title,dataIndex: v.index,key: v.key,align: 'center',})
        })
        
        
        this.deepMerge(pakarr, moneyArr)
        
        var titleArr = this.tableTile;

        
        
         var arrColumns = [
          {
          title: '',children: [
            {title: '日期',align: 'center',dataIndex: 'today',key:'today'} 
          ],
          },
          {
          title: '订单数',align: 'center',children: arrChildCount,
          },
          {
          title: '订单金额',align: 'center',children: arrNew
          }
        ]
        this.columns = arrColumns

        tableList.forEach(function(v,i){
          
          v['key']=i
          // v['date']= v.today+v.week
          
        })
        this.dataList = tableList

        
      },res=>{   

      });
    },

    onChangePage(page){
      this.pages = page;
      this.seach();
    },
      range(start, end) {
      
        const result = [];
        for (let i = start; i < end; i++) {
          result.push(i);
        }
      
        return result;
      },

      //显示编辑弹窗
        showModal() {
            this.visible = true;
        },
        //关闭编辑弹窗
        handleCancel() {
            this.visible = false;
        },
        //点击确认
        handleOk() {
            const form = this.form;
            form.validateFields((err, values) => {
                if (err) {
                    return;
                }
                console.log('form 表单内容: ', values);
                form.resetFields();
                this.visible = false;
            });
        },

      handleTableChanges(page, pageSize) {
        this.search.pageSize=page.pageSize;
        this.search.PageNumber=page.current;
        this.searchclick();
      
      },

      //获取时间段
      timeChange(date){ 
        
        if(date){
          this.createValue = date;
          this.searchInfo.startTime = date[0].format('YYYY-MM-DD');
          this.searchInfo.endTime = date[1].format('YYYY-MM-DD');

        }
      },
      disabledDate(current) {
        return current && current < moment().endOf('day');
      },
      disabledDateTime() {
        return {
          disabledHours: () => this.range(0, 24).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        };
      
    },

    disabledRangeTime(_, type) {
      if (type === 'start') {
        return {
          disabledHours: () => this.range(0, 60).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        };
      }
      return {
        disabledHours: () => this.range(0, 60).splice(20, 4),
        disabledMinutes: () => this.range(0, 31),
        disabledSeconds: () => [55, 56],
      };
    },

    // 终端来源
    sourceChange(value) {
      
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.find(item => key === item.key);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },


    //删除
    deleteMember(key){

    },

    

    
    // 导出方法
    output(){
      
       
        let urlstr = "/StatisticalAnalysis/exportAreaTargetStatistical"
        if(this.beginTime != "" && this.endTime != ""){
            urlstr=urlstr+"?b="+this.beginTime+"&e="+this.endTime;
        }
        this.$axios.get(urlstr,{responseType: 'blob'}).then((response) => {
            console.log(JSON.stringify(response))
            const blob = new Blob([response.data]); 
            //const fileName = response.headers['content-disposition'] //下载后文件名
            const fileName = '区域可疑目标统计.xls';
            const linkNode = document.createElement('a');
            linkNode.download = fileName; //a标签的download属性规定下载文件的名称
            linkNode.style.display = 'none';
            linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            linkNode.click();  //模拟在按钮上的一次鼠标单击

            URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
        })
            
    },

    // 清空搜索
    clearSeach(){
      let str = this.searchInfo.equipment
      // if(str != '设备'){
      //   str  = '设备'
      // }
      this.searchInfo={
         
          startTime:'',
          endTime:''
        }
      this.createValue=[]

    },
    start() {
      this.loading = true;
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },

    
  },
   mounted(){
     this.seach();
    
    }
}
</script>
<style scoped lang="less">
.orderStatist{padding-top: 20px;
.orderStatist .queryContent{width:80%!important;}
  .memberpx{width:18%!important;margin-right: 20px;}
  .clear{margin-top: 20px;height:80px;clear: both;overflow: hidden;}
  .queryContent{margin-top: 30px;}
  .operatTab a{padding: 2px 10px;background: #1890ff;color: #fff;border-radius: 5px;display: inline-block;margin-right: 8px;}
  .allCount{    display: inline-block;padding-top: 5px;margin-left: 10px;}
  .countryInt{width:400px;height: auto;position: absolute;z-index: 9999;opacity: 1;background: #fff;border: 1px solid #ccc;border-radius: 4px;top: 46px;
    left: 92px;font-size: 14px;padding: 20px;box-shadow: 6px 6px 5px #888888;}
    .areaInt{position: absolute;right:0;top: 20%;text-align: left;}
}
  
</style>
