<template>
  <div class="member">
    <div class="queryTop">
      <a-input v-model="searchInfo.order_no" class="memberpx" placeholder="订单号" />
      <a-input v-model="searchInfo.uid" class="memberpx" placeholder="用户ID" />
      <a-input v-model="searchInfo.pak_id" class="memberpx" placeholder="套餐ID" />

      <a-select placeholder="支付状态" style="width: 150px" v-model="searchInfo.payStatus"  class="memberpx">
        <a-select-option v-for="item in statusData" :value="item.value" :key="item.value">
          {{item.name}}
        </a-select-option>
      </a-select>
      
      <a-range-picker @change="timeChange" style="margin-right:20px;"
       :locale="locale" :value="createValue"
      :show-time="{
        hideDisabledOptions: true,
        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
      }" format="YYYY-MM-DD HH:mm:ss"/> 

        <a-button type="primary" icon="search" @click="seach">搜索</a-button>
        <a-button icon="close" style="margin-left:20px;margin-right:20px;" @click="clearSeach">清空搜索</a-button>
        <a-icon type="question-circle" @mouseenter="clearsClick" @mouseleave="clearsleave" style="font-size:20px;cursor: pointer;color:#888;"/>
          <div class="countryInt areaInt" v-if="clearsFlag" style="left: 68%;top: 190px;width: 500px;">
              <p>1、该列表可以查询所有的订单信息。客户端和官网每生成一个订单（无论是否付款成功），该列表就新增一行订单信息。</p>
              <p>2、搜索栏可多字段控制变量筛选订单详情。 </p>
              <p>3、开始日期~结束日期栏</p>
              <div>
                <p>（1）此处指的是：用户付费的日期。</p>
                <p>（2）通过对日期进行限定，从而搜索出在一定日期内下单用户的信息。</p>
              </div>
               <p>4、举例：如下图在客户端显示的两笔订单信息分别为：</p>
              <div>
                <p>&nbsp;&nbsp;  订单1： 用户A--购买套餐为：会员6月卡(送6个月)--订单金额为399元--已支付--
                  时间为2019.10.20  15:15:20</p>
                <p>&nbsp;&nbsp; 订单2： 用户A--购买套餐为：会员6月卡(送6个月)--订单金额为399元--未支付--
                  时间为2019.10.20  15:15:20</p>
                <img src="../assets/img/8.png" alt="" style="height: 200px;">
              </div>
          </div>
    </div>

    <div class="queryContent">
      <a-table bordered :data-source="dataSource" :columns="columns" :pagination="false">
       <a slot="name" slot-scope="text">{{ text }}</a>
          <template slot="statusIndex" slot-scope="text,record">
            <div class="editable-row-operations" >
              <span class="operatTab" v-if="record.status!='1'" style="color: red;">
                异常
              </span>
              <span class="operatTab" v-else>
                正常
              </span>
            </div>
          </template>
          <template slot="platformIndex" slot-scope="text,record">
            <div class="editable-row-operations" >
              <span class="operatTab" v-if="record.origin=='pc'">
                PC
              </span>
              <span class="operatTab" v-if="record.origin=='android'">
                Android
              </span>
              <span class="operatTab" v-if="record.origin=='ios'">
                IOS
              </span>
            </div>
          </template>

            <template>
              <span slot="mealName" style="position: relative;" >
                套餐ID
                  <a-icon type="question-circle" @mouseenter="areaClick" @mouseleave="arealeave" style="font-size:20px;cursor: pointer;color:#888;position: absolute;"  />
                  <div class="countryInt areaInt" v-if="areaFlag" style="left: 76PX;
      top: 5px">
                    套餐列表中的套餐ID。
      
                </div>
              </span>
          </template>
          <template>
              <span slot="payName" style="position: relative;" >
                支付单号
                  <a-icon type="question-circle" @mouseenter="payClick" @mouseleave="payleave" style="font-size:20px;cursor: pointer;color:#888;position: absolute;"  />
                  <div class="countryInt areaInt" v-if="payFlag" style="left: -219PX;top: 28px">
                    <p>支付单号不同于订单号，区别如下：</p>
                    <p>（1）订单号是下单后系统生成的，根据此订单号可以调取该订单的所有相关信息，</p>
                    <p>（2）支付单号即支付平台订单号，是支付平台（如微信、支付宝等）生成的，相当于支付平台给的小票。</p>
      
                </div>
              </span>
          </template>
      </a-table>

          <!-- 分页 -->
    <div class="pagein">
        <a-pagination v-model="pages" :total="total" :page-size="rows" show-less-items
            :show-total="total => `共${total}条数据`" @change="onChangePage(pages)" />
    </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment';
import common from '@/assets/js/Common'
import API from '@/assets/js/Api'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
export default {
  name: 'Member',
  components: {
  },
  data() {
    return {
      areaFlag:false,
      clearsFlag: false,
      payFlag: false,
      columns:[{
        title: 'ID',
        dataIndex: 'ID',
        width: '4%',
        align: 'center',
        scopedSlots: { customRender: 'ID' },
      },
      {
        title: '用户ID',
        dataIndex: 'uid',
        width: '5%',
        align: 'center',
        scopedSlots: { customRender: '用户ID' },
      },
      {
        title: '订单名称',
        dataIndex: 'name',
        width: '6%',
        align: 'center',
        scopedSlots: { customRender: '订单名称' },
      },
      {
        title: '订单号',
        dataIndex: 'order_no',
        width: '10%',
        align: 'center',
        scopedSlots: { customRender: '订单名称' },
      },
      {
        title: '金额',
        dataIndex: 'money',
        width: '6%',
        align: 'center',
        scopedSlots: { customRender: '金额' },
      },
      {
        dataIndex: 'pak_id',
        width: '6%',
        align: 'center',
        slots: { title: 'mealName'}
      },
       {
        title: '套餐名称',
        dataIndex: 'pak_name',
        width: '6%',
        align: 'center',
      },
      {
        title: '订单状态',
        dataIndex: 'status',
        width: '6%',
        align: 'center',
        scopedSlots: { customRender: 'statusIndex' },
      },
     {
        title: '支付状态',
        dataIndex: 'pay_status',
        width: '6%',
        align: 'center',
      },
      {
        title: '创建时间',
        dataIndex: 'create_time',
        width: '10%',
        align: 'center',
        scopedSlots: { customRender: '创建时间' },
      },
      {
        
        dataIndex: 'pay_sn',
        width: '8%',
        align: 'center',
        slots: { title: 'payName'},
        scopedSlots: { customRender: '支付单号' },
      },
      
      
      
      {
        title: '下单IP地址',
        dataIndex: 'ip',
        width: '8%',
        align: 'center',
        scopedSlots: { customRender: '下单ip地址' },
      },
      {
        title: '订单渠道',
        dataIndex: 'origin',
        width: '6%',
        align: 'center',
        scopedSlots: { customRender: 'platformIndex' },
      },
     
      
      // {
      //   title: '创建时间',
      //   dataIndex: 'create_time',
      //   width: '10%',
      //   align: 'center',
      //   scopedSlots: { customRender: '创建时间' },
      // },
      // {
      //   title: '用户注册时间',
      //   dataIndex: 'reg_time',
      //   width: '10%',
      //   align: 'center',
      //   scopedSlots: { customRender: '用户注册时间' },
      // },
    ],
      searchInfo: {
          terminalSourceClient:'全部终端',
          startTime:'',
          endTime:'',
          pak_id:'',
          order_no:'',
          uid:'',
          id:'',
          payStatus: undefined,
          payType:"支付方式",
          setMeal:"请选择套餐类型"
        },
         dateTime:'', //时间段
         locale,

        terminalSourceData:[
          {name: '全部终端', value: '全部终端'},
          {name: 'pc', value: 'pc'},
          {name: '手游', value: '手游'}
        ],
        statusData:[
          {name: '未支付', value: '未支付',id:1},
          {name: '已支付', value: '已支付',id:3},
        ],
        typeData:[
          {name: '支付方式', value: '支付方式'},
           {name: '微信', value: '微信'},
        ],
         mealData:[
          {name: '套餐', value: '套餐'},
           {name: '月卡', value: '月卡'},
        ],
        editingKey: '',
        pages:1,
        rows:10,
        total:10,
        dataSource:[],
        createValue: [],
      
    };
  },
    methods: {
      moment,
        //查询订单列表
    seach(){
      let status = this.searchInfo.payStatus;
      if(status == '未支付'){
        status = '1'
      }
      if(status == '已支付'){
        status = '3'
      }
      var ddd= common.trimSpace(this.searchInfo.order_no)
      console.log(ddd)
       let opts = {
              url: API.orderList,
              data: {
                
                order_no: common.trimSpace(this.searchInfo.order_no),
                uid: this.searchInfo.uid,
                pak_id: this.searchInfo.pak_id,
                pay_status: status,
                start: this.searchInfo.startTime,
                end: this.searchInfo.endTime,
                page:this.pages
              },       
        }
        
        common.query(opts).then(res=>{
          console.log(res)
          let arr = []
          let result = res.order_list;
          this.total = res.count
         
          result.forEach(function(v,i){
            console.log(v.origin)
            if(v.origin=='1'){
              v.origin = 'IOS'
            }
            if(v.origin=='2'){
              v.origin = 'Android'
            }
            if(v.origin=='3'){
              v.origin = 'PC'
            }
            if(v.origin=='4'){
              v.origin = 'WEB'
            }
            // if(v.status=='1'){
            //   v.status = '正常'
            // }
            // if(v.status!='1'){
            //   v.status = '异常'
            // }
            if(v.pay_status=='1'){
              v.pay_status = '未支付'
            }
            if(v.pay_status=='3'){
              v.pay_status = '已支付'
            }
            if(v.pay_status=='4'){
              v.pay_status = '退款'
            }
            
            arr.push({key: v.id, order_no: v.order_no,pak_id:v.pak_id, ID: v.id, money: v.money,pay_status: v.pay_status, name:v.name, origin: v.origin, orderId: v.id, uid: v.uid,status: v.status, pay_time: v.pay_time, ip: v.ip, pak_name: v.pak_name, pay_sn: v.pay_sn, create_time: v.create_time,reg_time: v.reg_time});

          })
          
           this.dataSource = arr;
      },res=>{   
          this.$message.error(res.remark);
      });
    },
    areaClick(){
      this.areaFlag = true;
    },
    arealeave(){
      this.areaFlag = false;
    },
    payClick(){
      this.payFlag = true;
    },
    payleave(){
      this.payFlag = false;
    },
      range(start, end) {
      
        const result = [];
        for (let i = start; i < end; i++) {
          result.push(i);
        }
      
        return result;
      },

      //获取时间段
      timeChange(date){
        if(date){
          this.createValue = date;
          this.searchInfo.startTime = date[0].format('YYYY-MM-DD HH:mm:ss');
          this.searchInfo.endTime = date[1].format('YYYY-MM-DD HH:mm:ss');

        }
      },
      disabledDate(current) {
        return current && current < moment().endOf('day');
      },

    disabledDateTime() {
      return {
        disabledHours: () => this.range(0, 24).splice(4, 20),
        disabledMinutes: () => this.range(30, 60),
        disabledSeconds: () => [55, 56],
      };
      
    },
    clearsClick(){
      this.clearsFlag = true;
    },
    clearsleave(){
      this.clearsFlag = false;
    },

    disabledRangeTime(_, type) {
      if (type === 'start') {
        return {
          disabledHours: () => this.range(0, 60).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        };
      }
      return {
        disabledHours: () => this.range(0, 60).splice(20, 4),
        disabledMinutes: () => this.range(0, 31),
        disabledSeconds: () => [55, 56],
      };
    },

    // 终端来源
    sourceChange(value) {
      
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.find(item => key === item.key);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },

    // 编辑操作功能
    edit(key) {
      let _this = this;
      const newData = [...this.data];   //请求接口的数据
      console.log(newData)
      newData.forEach((item,index)=>{
        if(key == index){
          item.editable=true;
          _this.data = newData
        }
      })
    },

    // 保存操作功能
    save(key) {
      const newData = [...this.data];
      
      const newCacheData = [...this.cacheData];
      const target = newData.find(item => key === item.key);
      const targetCache = newCacheData.find(item => key === item.key);
      if (target && targetCache) {
        delete target.editable;
        this.data = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = '';
    },

    
    // 导出方法
    output(){
        let token = common.storage('token');
     
    },

    // 清空搜索
    clearSeach(){
       this.createValue=[];
      
      this.searchInfo={
        order_no:'',
        pak_id:'',
        startTime:'',
        endTime:''
      }
      this.seach();
    },

    

    onChangePage(page){
      console.log(page)
      this.pages = page;
      this.seach();
    },
  },
  mounted(){
     this.seach();
    //  this.queryCountry();
    }
}
</script>
<style scoped lang="less">
.member{padding-top: 20px;
  .memberpx{width: 12%!important;margin-right: 2%;}
  .clear{margin-top: 20px;text-align: right;width: 90%;}
  .queryContent{margin-top: 30px;}
  .operatTab a{padding: 2px 10px;background: #1890ff;color: #fff;border-radius: 5px;display: inline-block;margin-right: 8px;}
  .countryInt{width:550px;height: auto;position: absolute;z-index: 9999;opacity: 1;background: #fff;border: 1px solid #ccc;border-radius: 4px;top: 46px;
    left: 160px;font-size: 14px;padding: 20px;box-shadow: 6px 6px 5px #888888;}
    .areaInt{width: 300px;position: absolute;right:0;top: 20%;text-align: left;}
}
  
</style>
