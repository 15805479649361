<template>
  <div class="daycount">

    <!-- 搜索条件 -->
    <div class="queryTop">
      
      <a-select @change="sourceChange" v-model="searchInfo.channelType" placeholder="设备"  class="memberpx">
        <a-select-option v-for="item in terminalSourceData" :value="item.value" :key="item.value">
          {{item.name}}
        </a-select-option>
      </a-select>
      <a-range-picker @change="timeChange" style="margin-right:20px;width: 24%" :locale="locale" :value="createValue"
      :show-time="{ hideDisabledOptions: true, defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')], }" format="YYYY-MM-DD HH:mm:ss"/> 
      
      <!-- <a-date-picker format="YYYY-MM-DD HH:mm:ss" @change="startChange" @ok="startOk"  placeholder="开始时间" :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }" :locale="locale"/>
       - <a-date-picker :locale="locale" show-time placeholder="结束时间" @change="finishChange" @ok="finishOk" style="margin-right: 1%;"/>  -->
      <a-button type="primary" icon="search" @click="seach">搜索</a-button>
      <a-button icon="close" style="margin-left:1%;" @click="clearSeach">清空搜索</a-button>
      <!-- <div class="clear">
        <a-button icon="download" @click="output" style="float: left;background:red;" :style="{ fontSize: '16px', color: '#fff' }" >导出</a-button>
        <span class="allCount">共有{{total}}条数据</span>
        
        
      </div> -->
    </div>


    <!-- 搜索内容列表 -->
    <div class="queryContent">

         <!-- 列表内容 -->
    <a-table bordered :data-source="dataSource" :columns="columns" :pagination="false">
       <a slot="name" slot-scope="text">{{ text }}</a>
       <template>
            <span slot="customName"  style="position: relative;">
                日活
                <a-icon type="question-circle" @mouseenter="areaClick" @mouseleave="arealeave" style="font-size:20px;cursor: pointer;color:#888;position: absolute;left: 110%;"  />
                <div class="countryInt areaInt" v-if="areaFlag"  style="left: 100%;top: 30px;">
                  <p>日活跃用户数量：</p>
                  <p>一日之内登录该产品（所有平台）的用户数（去除重复登录的用户）。</p>
                 

                </div>
            </span>
          </template>

          <template>
            <span slot="noRegister"  style="position: relative;">
                 非注册日活
                <a-icon type="question-circle" @mouseenter="noRegisterClick" @mouseleave="noRegisterleave" style="font-size:20px;cursor: pointer;color:#888;position: absolute;left: 110%;"  />
                <div class="countryInt areaInt" v-if="registerFlag"  style="left: 100%;top: 30px;">
                  <p>非注册日活：</p>
                  <p>一日之内登录该产品（所有平台）的用户数（去除重复登录的用户且去除当日注册的用户数）的平均值。</p>
                 

                </div>
            </span>
          </template>

          <template>
            <span slot="nextIndex"  style="position: relative;">
                 次日留存率
                <a-icon type="question-circle" @mouseenter="nextsClick" @mouseleave="nextsleavce" style="font-size:20px;cursor: pointer;color:#888;position: absolute;"  />
                <div class="countryInt areaInt" v-if="nextFlag"  style="left: -200%;top: 30px;">
                  <p>次日留存率：</p>
                  <p>（第1天新增的用户，在注册的第2天还继续使用该产品的用户数）/第1天新增总用户数。</p>
                 

                </div>
            </span>
          </template>
         
    </a-table>

    <!-- 分页 -->
    <div class="pagein">
        <a-pagination v-model="pages" :total="total" :page-size="rows" show-less-items
            :show-total="total => `共${total}条数据`" @change="onChangePage(pages)" />
    </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment';
import common from '@/assets/js/Common'
import API from '@/assets/js/Api'
import Modals from '../../components/Modal.vue'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
moment.locale('zh-cn')
export default {
  name: 'Member',
  components:{
   Modals
  },
  data() {
    return {
      areaFlag:false,
      registerFlag: false,
      nextFlag: false,

      columns : [
        {
          title: '日期',
          dataIndex: 'today',
          width: '7%',
          align: 'center',
          scopedSlots: { customRender: '日期' },
        },
        {
          title: '安装数',
          dataIndex: 'install_num',
          width: '6%',
          align: 'center',
          scopedSlots: { customRender: '安装数' },
        },
        {
          title: '注册数',
          dataIndex: 'reg_num',
          width: '10%',
          align: 'center',
          scopedSlots: { customRender: '注册数' },
        },
        {
          title: '启动次数',
          dataIndex: 'start_num',
          width: '5%',
          align: 'center',
          scopedSlots: { customRender: '启动次数' },
        },
        {
          
          dataIndex: 'day_num',
          width: '15%',
          align: 'center',
          slots: { title: 'customName'},

          scopedSlots: { customRender: '日活' },
        },
        {
         
          dataIndex: 'un_reg_num',
          width: '10%',
          align: 'center',
          slots: { title: 'noRegister'},

          scopedSlots: { customRender: '非注册日活' },
        },
        {
         
          dataIndex: 'retention',
          width: '10%',
          align: 'center',
          slots: { title: 'nextIndex'},

          
          scopedSlots: { customRender: '次日留存率' },
        },
        {
          title: '是否有更新',
          dataIndex: 'is_update',
          width: '10%',
          align: 'center',
          scopedSlots: { customRender: '是否有更新' },
        }
      ],
      startValue:[],
      dataSource: [],
      visible: false,    //编辑flag
      ModalText: 'Content of the modal',
      delectVisible: false,   //删除flag
      confirmLoading: false,
      idMember: 0,
      countryData:[],
      oemData:[{name: 'OEM', value: 'OEM'}],
      equipmentData:[
        {name: 'Windows', value: 'Windows'},
        {name: 'Android', value: 'Android'},
        {name: 'IOS', value: 'IOS'},
        {name: 'macOS', value: 'macOS'},

      ],

      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      searchInfo: {
        
        channelType:undefined,
        startTime:'',
        endTime:''
      },
      dateTime:[], //时间段
      locale,

      terminalSourceData:[
        {name: 'Android', value: 'Android'},
        {name: 'IOS', value: 'IOS'},
        {name: 'PC', value: 'PC'},
      ],
      editingKey: '',
      createValue: [],
      pages:1,
      rows:10,
      total:10,
    };
  },

  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
   
  beforeCreate() {
          //创建表单
          this.form = this.$form.createForm(this, { name: 'form_in_modal' });
  },
  
    methods: {
      moment,

    areaClick(){
      this.areaFlag = true;
    },
    arealeave(){
      this.areaFlag = false;

    },
    noRegisterClick(){
      this.registerFlag = true;
    },
    noRegisterleave(){
      this.registerFlag = false;
    },

    nextsClick(){
      this.nextFlag = true;
    },
    nextsleavce(){
      this.nextFlag = false;
    },
      // 选择开始时间
    startChange(value, dateString) {
      this.startValue = dateString
      this.searchInfo.startTime = dateString;
    },
    // 点击开始时间确认按钮
    startOk(value) {
      console.log('onOk: ', value);
      console.log(this.searchInfo.startTime,   value)
    },
    // 选择结束时间
    finishChange(value, dateString) {
      console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString);
      this.searchInfo.endTime = dateString;
    },
    // 点击结束时间确认按钮
    finishOk(value) {
      console.log('onOk: ', value);
      console.log(this.searchInfo.endTime,   value)
    },
       //查询国家设置
    seach(){
      var type = this.searchInfo.channelType;
      if (type=='IOS'){
        type = 'iphone'
      }
      if (type=='Android'){
        type = 'android'
      }
      if (type=='PC'){
        type = 'pc'
      }

      if(type==undefined){
        type=''
      }
       let opts = {
              url: API.dayList,
              data: {
               
                platform: type,
                start: this.searchInfo.startTime,
                end: this.searchInfo.endTime,
                page:this.pages
              },       
        }

        common.query(opts).then(res=>{
          
          let arr = []
          let resultList = res.list;
          this.total = res.count
        
          resultList.forEach(function(v,i){
            if(v.is_update==0){
            v.is_update='否'
            }else{
              v.is_update='是'
            }
            arr.push({key: v.id, retention:v.retention, today: v.today, install_num: v.install_num, reg_num: v.reg_num, start_num: v.start_num, day_num: v.day_num, un_reg_num: v.un_reg_num, one: v.one,is_update:v.is_update});

          })
          
           this.dataSource = arr;
      },res=>{   

      });
    },

    onChangePage(page){
      console.log(page)
      this.pages = page;
      this.seach();
    },
      range(start, end) {
      
        const result = [];
        for (let i = start; i < end; i++) {
          result.push(i);
        }
      
        return result;
      },

      //显示编辑弹窗
        showModal() {
            this.visible = true;
        },
        //关闭编辑弹窗
        handleCancel() {
            this.visible = false;
        },
        //点击确认
        handleOk() {
            const form = this.form;
            form.validateFields((err, values) => {
                if (err) {
                    return;
                }
                console.log('form 表单内容: ', values);
                form.resetFields();
                this.visible = false;
            });
        },

      handleTableChanges(page, pageSize) {
        this.search.pageSize=page.pageSize;
        this.search.PageNumber=page.current;
        this.searchclick();
      
      },
 
      //获取时间段
      timeChange(date){ 
        
        if(date){
          this.createValue = date;
          this.searchInfo.startTime = date[0].format('YYYY-MM-DD HH:mm:ss');
          this.searchInfo.endTime = date[1].format('YYYY-MM-DD HH:mm:ss');

        }
      },
      disabledDate(current) {
        return current && current < moment().endOf('day');
      },
      disabledDateTime() {
        return {
          disabledHours: () => this.range(0, 24).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        };
      
    },

    disabledRangeTime(_, type) {
      if (type === 'start') {
        return {
          disabledHours: () => this.range(0, 60).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        };
      }
      return {
        disabledHours: () => this.range(0, 60).splice(20, 4),
        disabledMinutes: () => this.range(0, 31),
        disabledSeconds: () => [55, 56],
      };
    },

    // 终端来源
    sourceChange(value) {
      
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.find(item => key === item.key);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },



    //删除
    deleteMember(key){

    },

    

    
    // 导出方法
    output(){
      
       
        let urlstr = "/StatisticalAnalysis/exportAreaTargetStatistical"
        if(this.beginTime != "" && this.endTime != ""){
            urlstr=urlstr+"?b="+this.beginTime+"&e="+this.endTime;
        }
        this.$axios.get(urlstr,{responseType: 'blob'}).then((response) => {
            console.log(JSON.stringify(response))
            const blob = new Blob([response.data]); 
            //const fileName = response.headers['content-disposition'] //下载后文件名
            const fileName = '区域可疑目标统计.xls';
            const linkNode = document.createElement('a');
            linkNode.download = fileName; //a标签的download属性规定下载文件的名称
            linkNode.style.display = 'none';
            linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            linkNode.click();  //模拟在按钮上的一次鼠标单击

            URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
        })
            
    },

    // 清空搜索
    clearSeach(){
      this.searchInfo={
          
          startTime:'',
          endTime:''
        }
      this.createValue=[]
      // this.startValue=[]
      // this.startValue
      this.seach();

    },
    start() {
      this.loading = true;
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },

    
  },
   mounted(){
     this.seach();
     
    }
}
</script>
<style scoped lang="less">
.daycount{padding-top: 20px;
  .memberpx{width:12%!important;margin-right: 20px;}
  .clear{margin-top: 20px;height:80px;clear: both;overflow: hidden;}
  .queryContent{margin-top: 30px;}
  .operatTab a{padding: 2px 10px;background: #1890ff;color: #fff;border-radius: 5px;display: inline-block;margin-right: 8px;}
  .allCount{    display: inline-block;padding-top: 5px;margin-left: 10px;}
  .countryInt{width:400px;height: auto;position: absolute;z-index: 9999;opacity: 1;background: #fff;border: 1px solid #ccc;border-radius: 4px;top: 46px;
    left: 92px;font-size: 14px;padding: 20px;box-shadow: 6px 6px 5px #888888;}
    .areaInt{position: absolute;right:0;top: 20%;text-align: left;}
}
  
</style>
