<template>
  <a-layout id="layout">
    <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
      <div class="logo" />
      <Menus  :activeKey="activeKey"></Menus>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0;display:flex;justify-content:space-between">
        <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        />
      <div style="margin-right:20px">
      <a-avatar icon="user" size="small" />
      <span style="display:inline-block; margin:0 8px;cursor:pointer" >{{userName}}</span>
      <a-icon type="close-circle" />
      <span style="display:inline-block; margin:0 8px;cursor:pointer" @click="logout">退出</span>
    </div>
      </a-layout-header>
       <Tabs  @sendKey="sendKey"></Tabs>
      <a-layout-content
        :style="{
          marginTop: '3px',
          padding: '0 24px',
          background: '#fff',
          minHeight: '280px',
        }"
      >
     
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import {mapState} from 'vuex'
import Menus from './Menus.vue'
import Tabs from './Tabs.vue'
export default {
  name: "layout",
  components:{
    Menus,
    Tabs
  },
  data() {
    return {
      collapsed: false,
      activeKey:undefined,
    };
  },
  computed:{
...mapState(['userName'])
  },
  methods:{
    sendKey(activeKey){
      console.log(activeKey)
      this.activeKey=activeKey
    },
    logout(){
      sessionStorage.removeItem('token')
      this.$router.push({path:'/login'})
    }
  }
};
</script>

<style scoped>
#layout{
  height: 100%;
}
#layout .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#layout .trigger:hover {
  color: #1890ff;
}

#layout .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
</style>
