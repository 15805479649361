<template>
<div class="loginContent">
  <div class="login">
    <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
      <a-form-model-item has-feedback label="用户名" prop="name">
        <a-input v-model="ruleForm.name" type="text" autocomplete="off" placeholder="admin" />
      </a-form-model-item>
      <a-form-model-item has-feedback label="密码" prop="checkPass">
        <a-input v-model="ruleForm.checkPass" type="password" autocomplete="off" placeholder="password" />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="submitForm('ruleForm')"> 提交 </a-button>
        <a-button style="margin-left: 10px" @click="resetForm('ruleForm')"> 重置 </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</div>

</template>
<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      ruleForm: {
        name: '',
        checkPass: '',
      },
      rules: {
        name: [{ required: true, message: 'admin', trigger: 'change' }],
        checkPass: [{ required: true, message: 'password', trigger: 'change' }],
      },
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
      },
    };
  },
  methods: {
    ...mapActions(['setTokenAction']),
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {

           //alert(this.ruleForm.name)
           //alert(this.ruleForm.checkPass)

          if(this.ruleForm.name === 'admin' && this.ruleForm.checkPass === "Zhengxing888."){
            this.setTokenAction({name:'admin',pass:'123456789'}).then(res=>{
              this.$router.push({path:'/layout/welcome'})
            })            
          }

           //this.setTokenAction({name:'admin',pass:'Zhengxing888.'}).then(res=>{
              //this.$router.push({path:'/layout/welcome'})
              //alert(11111111)
            //})


        } else {
          
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style scoped lang="less">
.loginContent{width: 100%;background: url(../assets/img/bg2.png) no-repeat center;background-size: cover; height:100%;padding-top:250px;
  .login{width:500px;margin: 0 auto;}
}

</style>