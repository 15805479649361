<template>
  <div class="member">
    <div class="queryTop">

      <a-button type="primary" style="margin-right: 10px;margin-bottom:30px;"  icon="plus" @click="AddConfigure()">添加套餐</a-button>
      <span @mouseenter="conClick" @mouseleave="conleave">
         <a-icon type="question-circle" style="font-size:22px;cursor: pointer;color:#888;"  />
      </span>
      <div>
      <div class="countryInt" v-if="cintrutFlag" style="top: 150px;left: 380px;width: 450px;">
        <p>1、该列表可查看、编辑所有套餐信息。每添加一个套餐，该列表就新增一行套餐信息，相应的设备终端的套餐页面就新增一个套餐。</p>
        <p>2、搜索栏可根据设备终端筛选线路详情。</p>
        <p>3、套餐列表的对应显示区域为：</p>
        <img src="../assets/img/9.png" alt="" style="height: 400px;" />
      </div>
        <a-select  style="width: 150px" @change="sourceChange" v-model="searchInfo.setMeal"  placeholder="设备"  class="memberpx">
          <a-select-option v-for="item in terminalSourceData" :value="item.value" :key="item.value">
            {{item.name}}
          </a-select-option>
        </a-select>
        <a-button type="primary" icon="search" @click="seach" style="margin-right:10px">搜索</a-button>
        <a-button icon="close" style="margin-right:20px;" @click="clearSeach">清空搜索</a-button>
      </div>
      

    
    </div>

    <div class="queryContent">
        <a-table :columns="columns" :data-source="dataSource" bordered :pagination="false">
          <a slot="name" slot-scope="text">{{ text }}</a>
          <template slot="operatindex" slot-scope="text, record">
            <div class="editable-row-operations">
              <span class="operatTab">
                
                <a :disabled="editingKey !== ''" @click="() => editList(record.key)">编辑</a>
                <a :disabled="editingKey !== ''" @click="() => deleteConfigure(record.key)">删除</a>
              </span>
            </div>
          </template>

          <template>
            <span slot="customName"  style="position: relative;">
                是否默认
                <a-icon type="question-circle" @mouseenter="areaClick" @mouseleave="arealeave" style="font-size:20px;cursor: pointer;color:#888;position: absolute;left: 110%;"  />

                <div class="countryInt areaInt" v-if="areaFlag" style="width: 450px;left: 0;top: 52px;">
                  <p>对“是否默认选中”栏的解释</p>
                  <p>1、“默认选中”的含义</p>
                  <p style="text-indent:24px">当点进套餐选择页面后，已有套餐被系统自动选中，用户不用</p>
                  <p style="text-indent:24px">自己选择即可直接付款，则该套餐就是被默认选中的套餐。</p>
                  <p>2、在同一个设备上，被默认选中的套餐只能有一个。</p>
                  <p>3、被默认选中套餐的展示效果：</p>
                  <p>其中，被选中的套餐为第一个。</p>

                  <img src="../assets/img/10.png" alt="" style="height: 150px;margin-bottom: 20px;">
                  <p>4、没有配置默认选中套餐的展示效果：</p>
                  <img src="../assets/img/11.png" alt="" style="height: 150px;">

                </div>
            </span>
          </template>
        </a-table>

        <!-- 分页 -->
    <div class="pagein">
        <a-pagination v-model="pages" :total="total" :page-size="rows" show-less-items
            :show-total="total => `共${total}条数据`" @change="onChangePage(pages)" />
    </div>
    </div>
    <!-- 添加弹窗 -->
      <div class="addBox">
        <a-modal :visible="visible" title="添加套餐" okText='确认' cancel-text="取消" @cancel="handleCancel" @ok="handleOk" width="700px" height="800px">
          <!--表单 并将表单的值绑定到this.from-->
          <a-form layout='vertical' :form="form" :label-col="{ span: 8}" :wrapper-col="{ span: 14 }">
            <!--每一项元素-->
            
            <a-form-item label='设备:'>
              <!-- <a-input placeholder="设备"  v-decorator="[ 'equipment', { rules: [{ required: true, message: '设备' }],  } ]" /> -->
              <a-select  style="width: 150px" @change="sourceChange" v-decorator="[ 'quipment', {initialValue:'Android', rules: [{ required: true, message: '设备' }],  } ]"  class="memberpx">
                <a-select-option v-for="item in terminalSourceData" :value="item.value" :key="item.value">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label='套餐名称:'>
              <a-input placeholder="套餐名称" v-decorator="[ 'name', { rules: [{ required: true, message: '套餐名称' }],  } ]" />
              <a-icon type="question-circle" @mouseenter="mealNameClick" @mouseleave="mealNameleave" style="font-size:20px;cursor: pointer;color:#888;position: absolute;left: 108%;"  />

            </a-form-item>
            <a-form-item label='套餐现价:'>
              <a-input placeholder="套餐现价" v-decorator="[ 'price', { rules: [{ required: true, message: '套餐价格' }],  } ]" />
            </a-form-item>
            <a-form-item label='套餐原价:'>
              <a-input placeholder="套餐原价" v-decorator="[ 'origin_price', { rules: [{ required: true, message: '套餐原价' }],  } ]" />
            </a-form-item>
            <a-form-item label='套餐单价:'>
              <a-input placeholder="套餐单价" v-decorator="[ 'sub_name', { rules: [{ required: true, message: '套餐单价' }],  } ]" />
            </a-form-item>
            <a-form-item label='优惠:'>
              <a-input placeholder="优惠" v-model="discount"/>
            </a-form-item>
            <a-form-item label='角标内容:'>
              <a-input placeholder="角标内容" v-model="corner" />
            </a-form-item>
            <a-form-item label='套餐天数:'>
              <a-input placeholder="套餐天数" v-decorator="[ 'day', { rules: [{ required: true, message: '套餐天数' }],  } ]" />
            </a-form-item>
            
           
            <!-- <a-form-item label='时长:'>
              <a-input placeholder="时长" v-decorator="[ 'time', { rules: [{ required: true, message: '时长' }],  } ]" />
            </a-form-item> -->
            <a-form-item label='是否默认选中:'>
                  <a-select class="memberpx"  v-decorator="['selectFlag',{initialValue:'是', rules: [{ required: true, message: '' }] },]">
                    <a-select-option v-for="item in selectData" :key="item.value">
                      {{item.name}}
                    </a-select-option>
                  </a-select>
              <a-icon type="question-circle" @mouseenter="selectedClick" @mouseleave="selectedleave" style="font-size:20px;cursor: pointer;color:#888;position: absolute;left: 108%;"  />

            </a-form-item>
            <!-- <a-form-item label='排序:'>
              <a-input placeholder="排序" v-decorator="[ 'countrSort', { rules: [{ required: true, message: '排序' }],  } ]" />
            </a-form-item> -->
            <!-- <a-form-item label='添加人:'>
              <a-input placeholder="添加人" v-decorator="[ 'addPeople', { rules: [{ required: true, message: '添加人)' }],  } ]" />
            </a-form-item>
            <a-form-item label='更新人:'>
              <a-input placeholder="更新人" v-decorator="[ 'updataPeople', { rules: [{ required: true, message: '更新人)' }],  } ]" />
            </a-form-item> -->
          </a-form>
        </a-modal>
      </div>

      <!-- 编辑弹窗 -->
      <div class="editBox">
        <a-modal :visible="visibleEdit" title="编辑套餐" okText='确认' cancel-text="取消" @cancel="editCancel" @ok="editOk" width="700px" height="800px">
          <!--表单 并将表单的值绑定到this.from-->
          <a-form layout='vertical' :form="formEdit" :label-col="{ span: 8}" :wrapper-col="{ span: 14 }">
            <!--每一项元素-->
            <a-form-item label='设备:'>
              <!-- <a-input placeholder="设备"  v-decorator="[ 'equipment', { rules: [{ required: true, message: '设备' }],  } ]" /> -->
              <a-select  style="width: 150px" @change="sourceChange" v-decorator="[ 'editEquipment', { rules: [{ required: true, message: '设备' }],  } ]"  class="memberpx">
                <a-select-option v-for="item in terminalSourceData" :value="item.value" :key="item.value">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label='套餐名称:'>
              <a-input placeholder="套餐名称" v-decorator="[ 'editName', { rules: [{ required: true, message: '套餐名称' }],  } ]" />
            </a-form-item>
            <a-form-item label='套餐现价:'>
              <a-input placeholder="套餐现价" v-decorator="[ 'editprice', { rules: [{ required: true, message: '套餐价格' }],  } ]" />
            </a-form-item>
            <a-form-item label='套餐原价:'>
              <a-input placeholder="套餐原价" v-decorator="[ 'editorigin_price', { rules: [{ required: true, message: '套餐原价' }],  } ]" />
            </a-form-item>
            <a-form-item label='套餐单价:'>
              <a-input placeholder="套餐单价" v-decorator="[ 'editsub_name', { rules: [{ required: true, message: '套餐单价' }],  } ]" />
            </a-form-item>
            <a-form-item label='优惠:'>
              <a-input placeholder="优惠" v-model="editdiscount" />
            </a-form-item>
            <a-form-item label='角标内容:'>
              <a-input placeholder="角标内容" v-model="editcorner" />
            </a-form-item>
            <a-form-item label='套餐天数:'>
              <a-input placeholder="套餐天数" v-decorator="[ 'editday', { rules: [{ required: true, message: '套餐天数' }],  } ]" />
            </a-form-item>
           
            <!-- <a-form-item label='时长:'>
              <a-input placeholder="时长" v-decorator="[ 'editTime', { rules: [{ required: true, message: '时长' }],  } ]" />
            </a-form-item> -->
            <a-form-item label='是否默认选中:'>
                  <a-select class="memberpx"  v-decorator="['editSelectFlag',{initialValue:'是', rules: [{ required: true, message: '' }] },]">
                    <a-select-option v-for="item in selectData" :key="item.value">
                      {{item.name}}
                    </a-select-option>
                  </a-select>
            </a-form-item>
            <!-- <a-form-item label='排序:'>
              <a-input placeholder="排序" v-decorator="[ 'countrSort', { rules: [{ required: true, message: '排序' }],  } ]" />
            </a-form-item> -->
            <!-- <a-form-item label='添加人:'>
              <a-input placeholder="添加人" v-decorator="[ 'editAddPeople', { rules: [{ required: true, message: '添加人)' }],  } ]" />
            </a-form-item>
            <a-form-item label='更新人:'>
              <a-input placeholder="更新人" v-decorator="[ 'editUpdataPeople', { rules: [{ required: true, message: '更新人)' }],  } ]" />
            </a-form-item> -->
          </a-form>
        </a-modal>
      </div>

      <div class="countryInt areaInt" v-if="mealNameFlag" style="top: 30%;left: 55%;width: 400px;" >
            <p>若配置套餐：</p>
            <p>（1）套餐名称为：“3月套餐”</p>
            <p>（2）套餐现价为：108元</p>
            <p>（3）套餐原价为：148元</p>
            <p>（4）套餐单价为：“27/月”</p>
            <p>（5）角标内容为：”送12个月“</p>
            <p>（6）套餐天数为：3+12=15个月，15*30=450天</p>
            <p>（7）是默认套餐</p>
            <p>则客户端相应显示如下：</p>
            <img src="../assets/img/12.png" alt="" style="height: 200px;margin-bottom: 20px;">

      </div>

      <div class="countryInt areaInt" v-if="selectedFlag" style="top: 30%;left: 38%;width: 500px;" >
            <p>对“是否默认选中”栏的解释</p>
            <p>（1）“默认选中”的含义</p>
            <p>当点进套餐选择页面后，已有套餐被系统自动选中，用户不用</p>
            <p>自己选择即可直接付款，则该套餐就是被默认选中的套餐。</p>
            <p>（2）在同一个设备上，被默认选中的套餐只能有一个。</p>
            <p>（3）被默认选中套餐的展示效果：</p>
            <p>其中，被选中的套餐为第一个。</p>
            <img src="../assets/img/10.png" alt="" style="height: 200px;margin-bottom: 20px;">
            <p>（4）没有配置默认选中套餐的展示效果：</p>

            
            <img src="../assets/img/11.png" alt="" style="height: 200px;margin-bottom: 20px;">

      </div>

  </div>
</template>

<script>

import moment from 'moment';
import common from '@/assets/js/Common'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import API from '@/assets/js/Api'

export default {
  name: 'Member',
  components: {
  },
  data() {
    return {
      areaFlag:false,
      mealNameFlag: false,
      corner:'',
      editcorner:'',
      columns: [
        {title:"套餐ID",dataIndex:"id",width:"4%",align:"center"},
        {title:"设备终端",dataIndex:"type",width:"6%",align:"center",scopedSlots:{customRender:"设备"}},
        {title:"套餐名称",dataIndex:"name",width:"8%",align:"center",scopedSlots:{customRender:"套餐名称"}},
        {title:"套餐时长/天",dataIndex:"day",width:"8%",align:"center"},
        {title:"套餐价格/元",dataIndex:"price",width:"8%",align:"center",scopedSlots:{customRender:"套餐价格"}},
        {dataIndex:"selected",width:"7%",align:"center",slots:{title:"customName"},scopedSlots:{customRender:"是否默认"}},
        {title:"添加人",dataIndex:"create_user",width:"5%",align:"center",scopedSlots:{customRender:"添加人"}},
        {title:"添加时间",dataIndex:"create_time",width:"10%",align:"center",scopedSlots:{customRender:"添加时间"}},
        {title:"更新人",dataIndex:"update_user",width:"5%",align:"center",scopedSlots:{customRender:"更新人"}},
        {title:"更新时间",dataIndex:"update_time",width:"10%",align:"center",scopedSlots:{customRender:"更新时间"}},
        {title:"操作",dataIndex:"index",align:"center",width:"8%",scopedSlots:{customRender:"operatindex"}}
      ],
      searchInfo: {
         
          setMeal: undefined,
          
      },
         dateTime:'', //时间段
         locale,

        terminalSourceData:[
          {name: 'Android', value: 'Android'},
          {name: 'IOS', value: 'IOS'},
          {name: 'PC', value: 'PC'},
          
        ],
        statusData:[
          {name: '支付状态', value: '支付状态'},
          {name: '未支付', value: '未支付'},
          {name: '已支付', value: '已支付'},
        ],
        typeData:[
          {name: '支付方式', value: '支付方式'},
           {name: '微信', value: '微信'},
        ],
         mealData:[
          {name: '套餐', value: '套餐'},
           {name: '月卡', value: '月卡'},
        ],
        selectData:[
          {name: '是', value: '是'},
          {name: '否', value: '否'},
        ],
        dataSource:[],
        editingKey: '',
        pages:1,
        rows:10,
        total:10,
        visible:false,
        form: this.$form.createForm(this),
        formEdit: this.$form.createForm(this),
        visibleEdit: false,
        mealId:'',
        discount:'',
        editdiscount:'',
        cintrutFlag: false,
        selectedFlag: false
      
      
    };
  },
    methods: {
      moment,

      range(start, end) {
      
        const result = [];
        for (let i = start; i < end; i++) {
          result.push(i);
        }
      
        return result;
      },

      areaClick(){
        this.areaFlag = true;
      },
      arealeave(){
        this.areaFlag = false;

      },
      mealNameClick(){
        this.mealNameFlag = true;
      },
      mealNameleave(){
        this.mealNameFlag = false;
      },
      selectedClick(){
        this.selectedFlag = true;
      },
      selectedleave(){
        this.selectedFlag = false
      },

      //获取时间段
      timeChange(date){
        if(date){
          this.searchInfo.startTime = date[0].format('YYYY-MM-DD HH:mm:ss');
          this.searchInfo.endTime = date[1].format('YYYY-MM-DD HH:mm:ss');

        }
      },
      disabledDate(current) {
        return current && current < moment().endOf('day');
      },

    conClick(){
      this.cintrutFlag = true
    },
    conleave(){
      this.cintrutFlag = false
    },

    disabledDateTime() {
      return {
        disabledHours: () => this.range(0, 24).splice(4, 20),
        disabledMinutes: () => this.range(30, 60),
        disabledSeconds: () => [55, 56],
      };
      
    },

    disabledRangeTime(_, type) {
      if (type === 'start') {
        return {
          disabledHours: () => this.range(0, 60).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        };
      }
      return {
        disabledHours: () => this.range(0, 60).splice(20, 4),
        disabledMinutes: () => this.range(0, 31),
        disabledSeconds: () => [55, 56],
      };
    },
     // 删除
    
    deleteConfigure(key){
      let _this = this;
      let opts = {
            url: API.delectMeal,
            data: {
              id: key
            },       
      }
      this.$confirm({
        title: '确定删除？',
        content: '',
        okText: '是的',
        okType: '',
        cancelText: '取消',
        onOk() {
          common.query(opts).then(res=>{
          
            _this.$message.success('删除成功');
            _this.seach();
          },res=>{   
            _this.$message.error(res.remark);
          });
        },
        onCancel() {
          
        },
      });
    },

     editCancel() {

        this.visibleEdit = false;

    },

    // 查询编辑指定套餐内容
    editList(key){
      this.mealId = key
      this.visibleEdit = true;
      this.editUrl = ''
       let opts = {
              url: API.mealInfo,
              data: {
                id: key
              },       
        }

        common.query(opts).then(res=>{
          var selected = res.selected;
          var type = res.type;
          if(selected == '0'){
            selected = '否'
          }
          if(selected == '1'){
            selected = '是'
          }
          if(type == '1'){
            type = 'IOS'
          }
          if(type == '2'){
            type = 'Android'
          }
          if(type == '3'){
            type = 'PC'
          }
          this.formEdit.setFieldsValue({
              editName: res.name,
              editEquipment: type,
              editprice: res.price,
              editTime: res.value,
              editSelectFlag:selected,             
             
              editorigin_price: res.origin_price,
              editday: res.day,
                           
              editsub_name: res.sub_name,
              countrSort: res.sorter

              

          })
          this.editcorner = res.corner,
          this.editdiscount =res.discount;
      },res=>{   
            this.$message.error(res.remark);
        
      });
    },  

    // 编辑确认按钮
    editOk(){
        const form = this.formEdit;
        
            let opts = {}
            form.validateFields((err, values) => {
                if (err) {
                    return;
                }
                if(values.editSelectFlag == '否'){
                  values.editSelectFlag = '0'
                }
                if(values.editSelectFlag == '是'){
                  values.editSelectFlag = '1'
                }
                if(values.editEquipment == 'IOS'){
                  values.editEquipment = '1'
                }
                if(values.editEquipment == 'Android'){
                  values.editEquipment = '2'
                }
                if(values.editEquipment == 'PC'){
                  values.editEquipment = '3'
                }
                
                opts = {
                    url: API.addMeal,
                    data: {
                      id: this.mealId,
                      type: values.editEquipment,
                      name:values.editName,
                      price:values.editprice,
                      selected: values.editSelectFlag,
                      create_user: values.editAddPeople,
                      update_user: values.editUpdataPeople,
                      origin_price: values.editorigin_price,
                      sub_name: values.editsub_name,
                      sorter: values.countrSort,
                      discount: this.editdiscount,
                      corner: this.editcorner,
                      day: values.editday,
                      pak_type: values.editEquipment
                    },       
                  }
            });
            common.query(opts).then(res=>{
              this.$message.success('修改成功');
              this.visibleEdit = false;  
              form.resetFields();  
            this.seach()
          },res=>{   
            this.$message.error(res.remark);
          });
    },

    // 终端来源
    sourceChange(value) {
      
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.find(item => key === item.key);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },

    //点击添加确认添加
    handleOk() {
        const form = this.form;
        let opts = {}
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            console.log('form 表单内容: ', values);
            if(values.selectFlag == '否'){
                  values.selectFlag = '0'
                }
                if(values.selectFlag == '是'){
                  values.selectFlag = '1'
                }
              if(values.quipment == 'IOS'){
                  values.quipment = '1'
                }
                if(values.quipment == 'Android'){
                  values.quipment = '2'
                }
                if(values.quipment == 'PC'){
                  values.quipment = '3'
                }
            opts = {
                url: API.addMeal,
                data: {
                  id:'',
                  type: values.quipment,
                  name:values.name,
                  price:values.price,
                  selected: values.selectFlag,
                  create_user: values.addPeople,
                  update_user: values.updataPeople,
                  origin_price: values.origin_price,
                  sub_name: values.sub_name,
                  sorter: values.countrSort,

                  discount: this.discount,
                  corner: this.corner,
                  day: values.day,
                  pak_type: values.quipment


                  
                },       
              }
        });
        common.query(opts).then(res=>{
          this.visible = false;
          this.$message.success('添加成功');
          form.resetFields();   
          this.seach()
      },res=>{   
        this.$message.error(res.remark);
      });
    },

     //关闭添加弹窗

    handleCancel() {

        this.visible = false;

    },

  
    
    // 导出方法
    output(){
        let token = common.storage('token');
        // common.getFile(api.appointOutput,token,
        // [{name:'projectId',value:this.searchInfo.projectId}]);
    },

    // 清空搜索
    clearSeach(){
      this.searchInfo.setMeal =undefined;
      this.seach()
    },

     //查询国家设置
    seach(){
      var str = this.searchInfo.setMeal
      var mealInfo = ''
      if(str == 'PC'){
        str = '3'
      }
      if(str == 'IOS'){
        str = '1'
      }
      if(str == 'Android'){
        str = '2'
      }
      if(str=="设备"){
        str = ""
      }
     
       let opts = {
              url: API.mealList,
              data: {
                
                pak_type: str,
                page:this.pages
              },       
        }

        common.query(opts).then(res=>{
          let arr = []
          let result = res.list;
          this.total = res.count
         
          result.forEach(function(v,i){
            if(v.selected ==1){
              v.selected ="是"
            }else{
              v.selected ="否"

            }
            if(v.type==1){
              v.type ='IOS'
            }
            if(v.type == 2){
              v.type = 'Android'
            }
            if(v.type == '3'){
              v.type = 'PC'
            }
            arr.push({key: v.id, id: v.id, name: v.name,create_user:v.create_user, update_user: v.update_user, type: v.type, price: v.price, day: v.day, selected: v.selected, create_time: v.create_time,update_time:v.update_time});

          })
          
           this.dataSource = arr;
      },res=>{   
            this.$message.error("错误");

      });
    },
    // 点击添加套餐按钮

      AddConfigure(key) {

        this.visible= true;
      },

    onChangePage(page){
      console.log(page)
      this.pages = page;
      this.seach();
    },
  },
   mounted(){
     this.seach();
    }
}
</script>
<style scoped lang="less">
.member{padding-top: 20px;
  .memberpx{width: 150px!important;margin-right: 20px;}
  .clear{margin-top: 20px;text-align: right;width: 90%;}
  .queryContent{margin-top: 30px;}
  .operatTab a{padding: 2px 10px;background: #1890ff;color: #fff;border-radius: 5px;display: inline-block;margin-right: 8px;}
  .countryInt{width:550px;height: auto;position: absolute;z-index: 9999;opacity: 1;background: #fff;border: 1px solid #ccc;border-radius: 4px;top: 46px;
    left: 160px;font-size: 14px;padding: 20px;box-shadow: 6px 6px 5px #888888;}
    .areaInt{width: 300px;position: absolute;right:0;top: 20%;text-align: left;}
}
  
</style>
