<template>
  <div class="member">
    <div class="queryTop">
      <a-button type="primary" style="margin-right: 10px;margin-bottom:30px;"  icon="plus" @click="AddConfigure('add')">添加版本</a-button>    
    </div>
    <div class="queryContent">
        <a-table :columns="columns" :data-source="dataSource" bordered :pagination="false">
          <a slot="name" slot-scope="text">{{ text }}</a>
          <template slot="operatindex" slot-scope="text, record">
            <div class="editable-row-operations">
              <span class="operatTab">
                
                <a :disabled="editingKey !== ''" @click="() => editList(record.key)">编辑</a>
                <a :disabled="editingKey !== ''" @click="() => deleteConfigure(record.key)">删除</a>
              </span>
            </div>
          </template>
        </a-table>

        <!-- 分页 -->
    <div class="pagein">
        <a-pagination v-model="pages" :total="total" :page-size="rows" show-less-items
            :show-total="total => `共${total}条数据`" @change="onChangePage(pages)" />
    </div>
    </div>
    <!-- 添加/编辑弹窗 -->
      <div class="addBox">
        <a-modal :visible="visible" title="信息" okText='确认' cancel-text="取消" @cancel="handleCancel" @ok="handleOk()" width="700px" height="800px">
          <!--表单 并将表单的值绑定到this.from-->
          <a-form layout='vertical' :form="form" :label-col="{ span: 8}" :wrapper-col="{ span: 14 }">
            <!--每一项元素-->
           
            <a-form-item label='版本号:'>
              <a-input placeholder="版本号" v-decorator="[ 'version_show', { rules: [{ required: true, message: '版本号不能为空' }],  } ]" />

            </a-form-item>
            <a-form-item label='内部版本号:'>
              <a-input placeholder="内部版本号" v-decorator="[ 'version', { rules: [{ required: true, message: '内部版本号不能为空' }],  } ]" />
            </a-form-item>
            <a-form-item label='内容大小(M):'>
              <a-input placeholder="内容大小" v-decorator="[ 'size', { rules: [{ required: true, message: '内容大小不能为空' }],  } ]" />
            </a-form-item>
            <a-form-item label='平台设备:'>
              <a-select  style="width: 150px" v-decorator="[ 'platform', {initialValue:'Android', rules: [{ required: true, message: '请选择设备' }],  } ]"  class="memberpx">
                <a-select-option v-for="item in terminalSourceData" :value="item.value" :key="item.value">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label='更新内容:'>
              <a-input placeholder="更新内容"  v-model="content"/>
            </a-form-item>
            <a-form-item label='下载地址:'>
              <a-input placeholder="下载地址" v-decorator="[ 'url', { rules: [{ required: true, message: '下载地址不能为空' }],  } ]" />
            </a-form-item>
            <a-form-item label='系统支持描述:'>
              <a-input placeholder="系统支持描述"  v-model="remark" />
            </a-form-item>
          </a-form>
        </a-modal>
      </div>
  </div>
</template>

<script>

import common from '@/assets/js/Common'
import API from '@/assets/js/Api'

export default {
  name: 'Version',
  components: {
  },
  data() {
    return {
        
        columns: [
            {title:"ID",dataIndex:"id",width:"4%",align:"center"},
            {title:"版本号",dataIndex:"version_show",width:"6%",align:"center",scopedSlots:{customRender:"版本号"}},
            {title:"内部版本号",dataIndex:"version",width:"5%",align:"center",scopedSlots:{customRender:"内部版本号"}},
            {title:"内容大小(M)",dataIndex:"size",width:"5%",align:"center"},
            {title:"平台设备",dataIndex:"platform",width:"6%",align:"center",scopedSlots:{customRender:"平台设备"}},
            {title:"下载地址",dataIndex:"url",width:"12%",align:"center",scopedSlots:{customRender:"下载地址"}},
            {title:"更新内容",dataIndex:"content",width:"5%",align:"center",scopedSlots:{customRender:"更新内容"}},
            {title:"更新日期",dataIndex:"update_time",width:"8%",align:"center",scopedSlots:{customRender:"更新日期"}},        
            {title:"创建日期",dataIndex:"create_time",width:"8%",align:"center",scopedSlots:{customRender:"创建日期"}},
            {title:"操作人员",dataIndex:"admin",width:"5%",align:"center",scopedSlots:{customRender:"操作人员"}},
            {title:"操作",dataIndex:"index",align:"center",width:"8%",scopedSlots:{customRender:"operatindex"}}
        ],
     

        terminalSourceData:[
        {name: 'Android', value: 'Android'},
        {name: 'IOS', value: 'IOS'},
        {name: 'PC', value: 'PC'},
        
        ],

        dataSource:[],
        editingKey: '',
        pages:1,
        rows:10,
        total:10,
        visible:false,
        form: this.$form.createForm(this),
       
        remark:'',
        content:'',
        id:'',
        remark_edit:'',
        content_edit:'',
      
    };
  },
    methods: {

     // 删除
    
    deleteConfigure(key){

      let _this = this;
      let opts = {
            url: API.versionDelete,
            data: {
              id: key
            },       
      }
      this.$confirm({
        title: '确定删除？',
        content: '',
        okText: '是的',
        okType: '',
        cancelText: '取消',
        onOk() {
          common.query(opts).then(res=>{
          
            _this.$message.success('删除成功');
            _this.seach();
          },res=>{   
            _this.$message.error(res.remark);
          });
        },
        onCancel() {
          
        },
      });
    },

     editCancel() {

        this.visibleEdit = false;

    },

    // 编辑指定版本内容
    editList(key){
        let _this = this
        this.id = key
        this.visible = true;
        this.editUrl = ''
        let opts = {
                url: API.versionEdit,
                data: {
                    id: key
                },       
            }
            
            common.query(opts).then(res=>{
            console.log(res.version_show)
            
            this.form.setFieldsValue({
                 version_show: res.version_show,
                 version: res.version,
                 platform: res.platform,
                 size: res.size,
                 url: res.url
            })
       

            this.content = res.content,
            this.remark = res.remark;
        },res=>{   
                this.$message.error(res.remark);
            
        });
    },  




    //点击添加确认添加
    handleOk(str) {
        console.log(str)
        const form = this.form;       
        let opts = {};
        form.validateFields((err, values) => {
           
            if (err) {
                 console.log(API.versionAdd)
                return;
            }
             console.log('form 表单内容: ', values);
            
            if(values.platform == 'IOS'){
                values.platform = 'iphone'
            }
            opts = {
                url: API.versionAdd,
                data: {
                    id: this.id,
                    version_show: values.version_show,	
                    version: values.version,
                    platform: values.platform.toLowerCase(),                    
                    size: values.size,                    
                    url: values.url,	
                    content: this.content,                  
                    remark:this.remark,
                },       
              }
              
        });
        console.log(opts)
       
        common.query(opts).then(res=>{
          this.visible = false;
          if(this.id ==''){
              this.$message.success('添加成功');
          }else{
              this.$message.success('更新成功');

          }
          
          form.resetFields();   
          this.seach()
      },res=>{   
        this.$message.error(res.remark);
      });
    },

     //关闭添加弹窗

    handleCancel() {

        this.visible = false;

    },

     //查询版本列表
    seach(){
       let opts = {
              url: API.versionList,
              data: {
                  page: this.pages
              },       
        }
        common.query(opts).then(res=>{
            console.log(res)
           res.list.forEach(function(v,i){
               v['key']=v.id
          })
          
            this.total = res.total;
            this.dataSource = res.list;        
      },res=>{   
            this.$message.error("错误");

      });
    },
    // 点击添加套餐按钮

      AddConfigure(key) {

        this.visible= true;
        setTimeout(() => {
            this.form.setFieldsValue({
                 version_show: '',
                 version: '',
                 size: ''
        })
        }, 0);
        
        this.content = '',
        this.remark = '',
        this.id = ''
      },

    // 分页页脚值page
    onChangePage(page){
      console.log(page)
      this.pages = page;
      this.seach();
    },
  },
   mounted(){
     this.seach();
    }
}
</script>
<style scoped lang="less">
.member{padding-top: 20px;
  .memberpx{width: 150px!important;margin-right: 20px;}
  .clear{margin-top: 20px;text-align: right;width: 90%;}
  .queryContent{margin-top: 30px;}
  .operatTab a{padding: 2px 10px;background: #1890ff;color: #fff;border-radius: 5px;display: inline-block;margin-right: 8px;}
  .countryInt{width:550px;height: auto;position: absolute;z-index: 9999;opacity: 1;background: #fff;border: 1px solid #ccc;border-radius: 4px;top: 46px;
    left: 160px;font-size: 14px;padding: 20px;box-shadow: 6px 6px 5px #888888;}
    .areaInt{width: 300px;position: absolute;right:0;top: 20%;text-align: left;}
}
  
</style>
