<template>
  <div class="orderStatist">

    <!-- 搜索条件 -->
    <div class="queryTop">
      
     <!-- <a-select default-value="设备"  placeholder="设备" v-model="searchInfo.equipment"  class="memberpx">
        <a-select-option v-for="item in equipmentData" :value="item.value" :key="item.value">
          {{item.name}}
        </a-select-option>
      </a-select> -->

      <a-range-picker @change="timeChange" style="margin-right:20px;width: 24%" :locale="locale" :value="createValue"
      :show-time="{ hideDisabledOptions: true, defaultValue: [moment(), moment()], }" format="YYYY-MM-DD" /> 
      <a-button type="primary" icon="search" @click="seach">搜索</a-button>
      <a-button icon="close" style="margin-left:1%;margin-right:1%;" @click="clearSeach">清空搜索</a-button>
      <a-icon type="question-circle" @mouseenter="clearsClick" @mouseleave="clearsleave" style="font-size:20px;cursor: pointer;color:#888;"/>
      <div class="countryInt areaInt" v-if="clearsFlag" style="left: 40%;top: 190px;width: 500px;">
          <p>1、以“1小时”为最小统计时间单位，以表格和折线图的方式展现一定时段的订单量。</p>
          <p>2、搜索栏：默认搜索最近7天（包括今天）的订单。 </p>
          
      </div>
    </div>


    <!-- 搜索内容列表 -->
    <div class="queryContent">

         <!-- 列表内容 -->
    <a-table bordered :data-source="dataSource" :columns="columns" :pagination="false" :scroll="{ x: 1500 }">
       <a slot="name" slot-scope="text">{{ text }}</a>
         
    </a-table>

    <!-- 分页 -->
    <div class="pagein">
        <a-pagination v-model="pages" :total="total" :page-size="rows" show-less-items
            :show-total="total => `共${total}条数据`" @change="onChangePage(pages)" />
    </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment';
import common from '@/assets/js/Common'
import API from '@/assets/js/Api'
import Modals from '../../components/Modal.vue'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
moment.locale('zh-cn')
export default {
  name: 'Member',
  components:{
   Modals
  },
  data() {
    this.dateFormat = 'YYYY/MM/DD'
    return {
      clearsFlag: false,
      columns : [
        {
          title: '日期',
          dataIndex: 'today',
          width:'70px',
          align: 'center',
          scopedSlots: { customRender: '日期' },
        },
        {
          title: '总计',
          dataIndex: 'totals',
         width:'70px',
          align: 'center',
        },
        {
          title: '00:00-01:00',
          dataIndex: 'hour_00',
          width:'70px',
          align: 'center',
          scopedSlots: { customRender: '订单金额' },
        },
        {
          title: '01:00-02:00',
          dataIndex: 'hour_01',
         width:'70px',
          align: 'center',
        }
        ,
        {
          title: '02:00-03:00',
          dataIndex: 'hour_02',
          width:'70px',
          align: 'center',
        },
        {
          title: '03:00-04:00',
          dataIndex: 'hour_03',
          width:'70px',
          align: 'center',        
        },
        {
          title: '04:00-05:00',
          dataIndex: 'hour_04',
          width:'70px',
          align: 'center',          
        },
        {
          title: '05:00-06:00',
          dataIndex: 'hour_05',
          width:'70px',
          align: 'center',          
        },
        {
          title: '06:00-07:00',
          dataIndex: 'hour_06',
         width:'70px',
          align: 'center',          
        },
        {
          title: '07:00-08:00',
          dataIndex: 'hour_07',
          width:'70px',
          align: 'center',          
        },
        {
          title: '08:00-09:00',
          dataIndex: 'hour_08',
          width:'70px',
          align: 'center',          
        },
        {
          title: '09:00-10:00',
          dataIndex: 'hour_09',
          width:'70px',
          align: 'center',          
        },
        {
          title: '10:00-11:00',
          dataIndex: 'hour_10', 
          width:'70px',
          align: 'center',          
        },
        {
          title: '11:00-12:00',
          dataIndex: 'hour_11',
          width:'70px',
          align: 'center',          
        },
        {
          title: '12:00-13:00',
          dataIndex: 'hour_12',
          width:'70px',
          align: 'center',          
        },
        {
          title: '13:00-14:00',
          dataIndex: 'hour_13',
          width:'70px',
          align: 'center',          
        },
        {
          title: '14:00-15:00',
          dataIndex: 'hour_14',
          width:'70px',
          align: 'center',          
        },
        {
          title: '15:00-16:00',
          dataIndex: 'hour_15',
          width:'70px',
          align: 'center',          
        },
        {
          title: '16:00-17:00',
          dataIndex: 'hour_16',
         width:'70px',
          align: 'center',          
        },
        {
          title: '17:00-18:00',
          dataIndex: 'hour_17',
          width:'70px',
          align: 'center',          
        },
        {
          title: '18:00-19:00',
          dataIndex: 'hour_18',
          width:'70px',
          align: 'center',          
        },
         {
          title: '19:00-20:00',
          dataIndex: 'hour_19',
          width:'70px',
          align: 'center',          
        },
        {
          title: '20:00-21:00',
          dataIndex: 'hour_20',
          width:'70px',
          align: 'center',          
        },
         {
          title: '21:00-22:00',
          dataIndex: 'hour_21',
          width:'70px',
          align: 'center',          
        },
        {
          title: '22:00-23:00',
          dataIndex: 'hour_22',
          width:'70px',
          align: 'center',          
        },
        {
          title: '23:00-24:00',
          dataIndex: 'hour_23',
          width:'70px',
          align: 'center',          
        }
        
        
      ],
      dataSource: [],
      visible: false,    //编辑flag
      ModalText: 'Content of the modal',
      delectVisible: false,   //删除flag
      confirmLoading: false,
      idMember: 0,
      countryData:[],
      oemData:[{name: 'OEM', value: 'OEM'}],
      equipmentData:[
        {name: 'Android', value: 'Android'},
        {name: 'IOS', value: 'IOS'},
        {name: 'PC', value: 'PC'},

      ],

      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      pType: '渠道类型',
      searchInfo: {
        equipment: undefined,
        startTime:'2021-04-21',
        endTime:'2021-04-21',
        sevenDate:'2021-04-21',
        today: '2021-04-21'
      },
      dateTime:[], //时间段
      locale,


      editingKey: '',
      createValue: [],
      pages:1,
      rows:10,
      total:10,
    };
  },

  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },

  beforeCreate() {
          //创建表单
          this.form = this.$form.createForm(this, { name: 'form_in_modal' });
  },
  
    methods: {
      moment,
      clearsClick(){
        this.clearsFlag = true;
      },
      clearsleave(){
        this.clearsFlag = false;

      },
       //查询国家设置
    seach(){
       let opts = {
              url: API.segmentTimeCount,
              data: {
               
                
                page:this.pages,
                start: this.searchInfo.startTime,
                end: this.searchInfo.endTime
              },       
        }
        this.dataSource = []

        common.query(opts).then(res=>{
          let arr = []
          let result = res.list;
          this.total = res.total
          for(var i=0;i<result.length;i++){
            result[i]['id']=i
          }
          
          result.forEach(function(v,i){
            arr.push({key: v.id, today: v.today, totals: v.total, hour_00: v.hour_00, hour_01: v.hour_01,
             hour_02: v.hour_02, hour_03: v.hour_03, hour_04: v.hour_04,hour_05: v.hour_05, hour_06:v.hour_06
             ,hour_07: v.hour_07 ,hour_08: v.hour_08, hour_09: v.hour_09,hour_10: v.hour_10, hour_11:v.hour_11
             ,hour_12: v.hour_12 ,hour_13: v.hour_13, hour_14: v.hour_14,hour_15: v.hour_15, hour_16:v.hour_16
             ,hour_17: v.hour_17 ,hour_18: v.hour_18, hour_19: v.hour_19,hour_20: v.hour_20, hour_21:v.hour_21
             ,hour_22: v.hour_22 ,hour_23: v.hour_23
            });

          })
          
           this.dataSource = arr;
      },res=>{   

      });
    },

    onChangePage(page){
     
      this.pages = page;
      this.seach();
    },
      range(start, end) {
      
        const result = [];
        for (let i = start; i < end; i++) {
          result.push(i);
        }
      
        return result;
      },

      //显示编辑弹窗
        showModal() {
            this.visible = true;
        },
        //关闭编辑弹窗
        handleCancel() {
            this.visible = false;
        },
        //点击确认
        handleOk() {
            const form = this.form;
            form.validateFields((err, values) => {
                if (err) {
                    return;
                }
                console.log('form 表单内容: ', values);
                form.resetFields();
                this.visible = false;
            });
        },

      handleTableChanges(page, pageSize) {
        this.search.pageSize=page.pageSize;
        this.search.PageNumber=page.current;
        this.searchclick();
      
      },
       // 获取国家列表
  queryCountry(){
     let opts = {
              url: API.queryCountry,
              data: {},       
        }

        common.query(opts).then(res=>{
          var arr = []
          res.forEach(function(v,i){
            arr.push({name:v.name,value:v.name, code: v.code})
          })
          // arr.unshift({name:'用户所在地',value:'用户所在地'})
          this.countryData = arr;

          console.log(this.countryData)
        })

           
      
  },

  // 获取当天日期 -7天日期
  getDate(){
    let today = new Date(new Date()).toLocaleDateString().split('/').map(item=>{if (item<10){return '0'+ item}else {return item}}).join('-')
    let sevenDate = new Date(new Date() - 24*60*60*1000*6).toLocaleDateString().split('/').map(item=>{if (item<10){return '0'+ item}else {return item}}).join('-')
    this.searchInfo.today= today;
    this.searchInfo.sevenDate = sevenDate;
    this.searchInfo.startTime = sevenDate;
    this.searchInfo.endTime = today;
    this.createValue=[moment(this.searchInfo.startTime,'YYYY/MM/DD'), moment(this.searchInfo.endTime,'YYYY/MM/DD')]
    console.log(this.searchInfo.sevenDate )
    this.seach();

  },
  
      //获取时间段
      timeChange(date){ 
        
        if(date){
          console.log(date)
          this.createValue = date;
          this.searchInfo.startTime = date[0].format('YYYY-MM-DD');
          this.searchInfo.endTime = date[1].format('YYYY-MM-DD');

        }
      },
      disabledDate(current) {
        return current && current < moment().endOf('day');
      },
      disabledDateTime() {
        return {
          disabledHours: () => this.range(0, 24).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        };
      
    },

    disabledRangeTime(_, type) {
      if (type === 'start') {
        return {
          disabledHours: () => this.range(0, 60).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        };
      }
      return {
        disabledHours: () => this.range(0, 60).splice(20, 4),
        disabledMinutes: () => this.range(0, 31),
        disabledSeconds: () => [55, 56],
      };
    },

    // 终端来源
    sourceChange(value) {
      
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.find(item => key === item.key);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },


 

    //删除
    deleteMember(key){

    },

    

    
    // 导出方法
    output(){
      
       
        let urlstr = "/StatisticalAnalysis/exportAreaTargetStatistical"
        if(this.beginTime != "" && this.endTime != ""){
            urlstr=urlstr+"?b="+this.beginTime+"&e="+this.endTime;
        }
        this.$axios.get(urlstr,{responseType: 'blob'}).then((response) => {
            console.log(JSON.stringify(response))
            const blob = new Blob([response.data]); 
            //const fileName = response.headers['content-disposition'] //下载后文件名
            const fileName = '区域可疑目标统计.xls';
            const linkNode = document.createElement('a');
            linkNode.download = fileName; //a标签的download属性规定下载文件的名称
            linkNode.style.display = 'none';
            linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            linkNode.click();  //模拟在按钮上的一次鼠标单击

            URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
        })
            
    },

    // 清空搜索
    clearSeach(){
      let str = this.searchInfo.equipment
      this.getDate()
      this.createValue=[moment(this.searchInfo.sevenDate,'YYYY/MM/DD'), moment(this.searchInfo.today,'YYYY/MM/DD')]
    },
    start() {
      this.loading = true;
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },

    
  },
   mounted(){
   
    //  this.queryCountry();
    this. getDate();
    }
}
</script>
<style scoped lang="less">
.orderStatist{padding-top: 20px;
.orderStatist .queryContent{width:80%!important;}
  .memberpx{width:18%!important;margin-right: 20px;}
  .clear{margin-top: 20px;height:70px;clear: both;overflow: hidden;}
  .queryContent{margin-top: 30px;}
  .operatTab a{padding: 2px 10px;background: #1890ff;color: #fff;border-radius: 5px;display: inline-block;margin-right: 8px;}
  .allCount{    display: inline-block;padding-top: 5px;margin-left: 10px;}
   .countryInt{width:400px;height: auto;position: absolute;z-index: 9999;opacity: 1;background: #fff;border: 1px solid #ccc;border-radius: 4px;top: 46px;
    left: 92px;font-size: 14px;padding: 20px;box-shadow: 6px 6px 5px #888888;}
    .areaInt{position: absolute;right:0;top: 20%;text-align: left;}
}
  
</style>
