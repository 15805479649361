
import Vue from 'vue'
const VUE = new Vue();

export default {
    query(opts){
        let _this = this;
        return new Promise((resolve,reject)=>{

            let queryOpts = {
                url: opts.url,
                data: opts.data || {},
                type: opts.type || 'POST',
                async: opts.async || true,
                dataType: 'json',
                contentType: opts.contentType, // 设置传递类类型 请求头
                processType: opts.processType, // 设置传递类型 json 
                success: function(data){
                    switch(data.code){
                        case 200:
                            resolve(data.result);
                            break;
                        case 4100:
                            resolve(data);
                            break;    
                        case 4000:
                            reject(data);
                            break;    
                    }
                },
                error: function(data){
                    // let d = {
                    //     success: false,
                    //     errorCode: data.status,
                    //     errorMessage: '请求出错:cus='+data.code,
                    //     data:null,
                    // }
                    let d = '请求出错'
                    reject(d);
                    // VUE.$Loading.error();
                }
            };
            // 添加header [ 如果不写 ]
            // if(typeof opts.header==='undefined'|| typeof opts.header==='null'|| opts.header==true){
            //     queryOpts['headers'] = {
            //         'token': this.storage('token'),
            //     }
            // }
            this.ajax(queryOpts);
        });

    },

    // 设置与返回 localStorage / sessionStorage
    storage(key,value,type){
        if(value){
            type == 'session' ? sessionStorage.setItem(key,value) : localStorage.setItem(key,value);
        }else{
            return type == 'session' ? sessionStorage.getItem(key) : localStorage.getItem(key);
        }
    },

    // 删除localStorage / sessionStorage
    removeStorage(key,type){
        type == 'session' ? sessionStorage.removeItem(key) : localStorage.removeItem(key);
    },

    // 去除左右空格
    trimSpace(v){
        return v.replace(/^\s+|\s+$/g,'');
    },

    // 日期格式化
    formatDate(v,type){
        let y = this.fillZore(v.getFullYear());
        let m = this.fillZore(v.getMonth() + 1);
        let d = this.fillZore(v.getDate());
        let h = this.fillZore(v.getHours());
        let i = this.fillZore(v.getMinutes());
        let s = this.fillZore(v.getSeconds());

        if(type=='ymd'){
            return y+'-'+m+'-'+d;
        }else if(type == 'hi'){
            return h+':'+i;
        }else{
            return y+'-'+m+'-'+d+' '+h+':'+i+':'+s;
        }
    },

    // 补前置0
    fillZore(v){
        return v <= 9 ? '0' + v : v;
    },

    // 清除状态关闭的分类
    clearCategoryStatus(data){
        let _this = this;
        let arr = [];
        data.forEach(function(v,i){
            if(!v.status){
                return false;
            }
            let m = {
                value: v.id,
                label: v.name,
                pid: v.pid,
                children: [],
            };
            if(v.childNode.length>0){
                m['children'] = _this.clearCategoryStatus(v.childNode);
            }
            arr.push(m);
        });
        return arr;
    },

    // 联级菜单处理 + 过滤没有子分类的主分类 + 过滤关闭子分类的主分类
    cascaderDataOption(data){
        let arr = [];
        data.forEach(function(v,i){
            if(v.pid==0 && v.children.length==0){
                return false;
            }
            arr.push(v);
        });
        return arr;
    },

    // 对象复制
    copyObject(val){
        let n = [];
        for(let v in val){

        }
        return n;
    },


    ajax(opt){

        let xhr = new XMLHttpRequest();
        
        // 监听请求状态
        xhr.onreadystatechange=function(){
            if(xhr.readyState==4){
                if(xhr.status == 200){
                    return opt.success(opt.dataType == 'json' ? JSON.parse(xhr.responseText):xhr.responseText);
                }else{
                    return opt.error(xhr);
                }
            }else{
                // 请求还在处理中
            }
        }

        xhr.onloadend = function(e){
            if(xhr.status!=200){
                return opt.error(xhr);
            }
        }

        if(opt.type==='GET'){
            let url = opt.url+'?'+urlMerge(opt.data);
            xhr.open(opt.type,url,opt.async);
            // 设置header
            if(typeof opt.headers=='object'){
                for(let h in opt.headers){
                    xhr.setRequestHeader(h,opt.headers[h]);
                }
            }
            xhr.send();
        }else{
            xhr.open(opt.type,opt.url,opt.async);

            //  上传类型
            //  如果传递的是自定义类型的话
            if(opt.contentType){
                xhr.setRequestHeader('Content-Type',opt.contentType);
            }
            if(typeof opt.contentType=='undefined'){
                xhr.setRequestHeader('Content-Type',"application/x-www-form-urlencoded");
            }
            // }else{
            //     xhr.setRequestHeader('Content-type',"mutipart/form-data");
            // }
            
            // 设置header
            if(typeof opt.headers === 'object'){
                for(let h in opt.headers){
                    xhr.setRequestHeader(h,opt.headers[h]);
                }
            }

            // 不处理数据processData
            if(typeof opt.processType=='undefined'){
                xhr.send(urlMerge(opt.data));
            }

            // 如果传递是json对象则需要转化下
            if(opt.processType == 'JSON'){
                xhr.send(JSON.stringify(opt.data));
            }
        }

        function urlMerge(s){
            let str = '';
            for(let x in s){
                if(typeof s[x]=='string'||typeof s[x]=='number' || typeof s[x]=='boolean'){
                    str += '&'+x +'='+s[x];  
                }
            }
            return str.substr(1);
        }

    },

    // 导出方法
    getFile(url,token,args,type){
        let type1 = type ? type :'post';
        let form = document.createElement('form');
        form.setAttribute('style', 'display:none');
        form.setAttribute('method', type1); //请求方式
        form.setAttribute('action', url);//请求地址
        document.body.appendChild(form);

        let token1 = document.createElement('input');//将你请求的数据模仿成一个input表单

        token1.setAttribute('type', 'hidden');
        token1.setAttribute('name', 'token');//该输入框的name
        token1.setAttribute('value',token);//该输入框的值
        form.append(token1);

        for(let obj of args){
            let input1 = document.createElement('input');//将你请求的数据模仿成一个input表单
            input1.setAttribute('type', 'hidden');
            input1.setAttribute('name', obj.name);//该输入框的name
            input1.setAttribute('value',obj.value);//该输入框的值
            form.append(input1);
        }
        form.submit();
        form.remove();
    },
    
}
