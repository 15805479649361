<template>
  <div class="version">

    <!-- 查询条件 -->
     <div class="queryTop" style="position: relative;">
       <a-button type="primary" style="margin-right: 10px;margin-top:20px;margin-bottom:20px;"  icon="plus" @click="AddConfigure()">添加线路</a-button>
        <span @mouseenter="conClick" @mouseleave="conleave">
         <a-icon type="question-circle" style="font-size:22px;cursor: pointer;color:#888;position: absolute;top: 23px;"  />
       </span>
       <div class="searchBottom">
            <a-input v-model="lineInfo.name"  class="memberpx" placeholder="线路名称" />
            <a-input v-model="lineInfo.ip"  class="memberpx" placeholder="线路IP" />
            <a-input v-model="lineInfo.port"  class="memberpx" placeholder="协议端口" />
            <!-- <a-input v-model="lineInfo.domain_name"  class="memberpx" placeholder="连接域名" /> -->
            <a-button type="primary" icon="search" @click="seach">搜索</a-button>
            <a-button icon="close" style="margin-left:20px;" @click="clearSeach">清空搜索</a-button>
       </div>
    </div>
    
    <div class="countryInt" v-if="cintrutFlag">
      <p>1、该列表可查看、编辑所有线路信息。每添加一个线路，该列表就新增一行线路信息，客户端“线路节点”页面就在相应的国家/地区就新增一个线路。</p>
      <p>2、搜索栏可多字段控制变量筛选线路详情。</p>
      <p>3、举例：若配置后台的线路为”美国-线路节点-1、美国-线路节点-2、美国-线路节点-3、美国-线路节点-4”，则在客户端的对应显示如下：</p>
      <img src="../assets/img/3.png" alt="" style="height: 450px;">
    </div>

    <!-- 列表内容 -->
    <a-table bordered :data-source="dataSource" :columns="columns" :pagination="false">
       <a slot="name" slot-scope="text">{{ text }}</a>
          <template slot="operatindex" slot-scope="text, record">
            <div class="editable-row-operations">
              <span class="operatTab">
                
                <a :disabled="editingKey !== ''" @click="() => editList(record.key)">编辑</a>
                <a :disabled="editingKey !== ''" @click="() => deleteLine(record.key)">删除</a>
              </span>
            </div>
          </template>
          <template slot="statusIndex" slot-scope="text, record">
            <div class="editable-row-operations">
              <span class="operatTab" v-if="record.status=='1'">
                
                正常
              </span>
              <span class="operatTab" v-if="record.status!='1'">
                
                禁用
              </span>
            </div>
          </template>
    </a-table>

     <!-- 分页 -->
    <div class="pagein">
        <a-pagination v-model="pages" :total="total" :page-size="rows" show-less-items
            :show-total="total => `共${total}条数据`" @change="onChangePage(pages)" />
    </div>

       <!-- 添加线路 -->
      <div class="addBox">
        <a-modal :visible="visible" title= "添加线路" okText='确认' cancel-text="取消" @cancel="handleCancel" @ok="handleOk" width="800px"  height="800px">
            <!--表单 并将表单的值绑定到this.from-->
            <a-form layout='vertical' :form="form" :label-col="{ span: 6}" :wrapper-col="{ span: 16 }">
                <!--每一项元素-->
                <a-form-item label='线路状态:'>
                    <a-select  class="memberpx"  v-decorator="['lineStatus',{initialValue:'正常', rules: [{ required: true, message: '请选择线路状态!' }] },]">
                      <a-select-option v-for="item in statusData" :key="item.value">
                        {{item.name}}
                      </a-select-option>
                    </a-select>
                </a-form-item>
                <!-- <a-form-item label='线路分类:'>
                    <a-select  class="memberpx"  v-decorator="['lineType',{initialValue:'非洲线路', rules: [{ required: true, message: '请选择线路分类!' }] },]">
                      <a-select-option v-for="item in typeData" :value="item.value" :key="item.value">
                        {{item.name}}
                      </a-select-option>
                    </a-select>
                </a-form-item> -->
                <a-form-item label='线路名称:'>
                    <a-input placeholder="线路名称" v-decorator="[ 'names', {initialValue:'', rules: [{ required: true, message: '线路名称' }],  } ]" />
                </a-form-item>
                <a-form-item label='IP:'>
                    <a-input placeholder="IP" v-decorator="[ 'ipAdds', {initialValue:'', rules: [{ required: true, message: 'IP' }],  } ]" />
                </a-form-item>
                <a-form-item label='地区:'>
                    <a-select style="width:48%;margin-right:4%;"  @change="areaChange"  class="memberpx" placeholder="所在地区">
                      <a-select-option v-for="item in countryData" :value="item.value+'/'+item.code" :key="item.value" :code="item.code">
                        {{item.name}}
                      </a-select-option>
                    </a-select>
                  
                    
                </a-form-item>
                <a-form-item label='支持设备:'>
                    <a-checkbox-group @change="onChangeCheck" :value="lineInfo.checkArr">
                        <a-row>
                            <a-col>
                                <a-checkbox value="Android">Android</a-checkbox>
                                <a-checkbox value="IOS">IOS</a-checkbox>
                                <a-checkbox value="PC">PC</a-checkbox>
                            </a-col>
                                                   
                        </a-row>
                    </a-checkbox-group>
                </a-form-item>
                
                <a-form-item label='移动端协议端口：'>
                    <a-input v-model="lineInfo.agreePort"  placeholder="移动端协议端" />
                </a-form-item>
                <a-form-item label='PC驱动协议端口：'>
                    <a-input v-model="lineInfo.pcPort"  placeholder="PC驱动协议端口" />
                </a-form-item>
                <a-form-item label='备注'>
                    <a-input v-model="lineInfo.desc" type="textarea" />
                </a-form-item>
                <a-form-item label='排序:'>
                  <a-input placeholder="排序" v-decorator="[ 'countrSort', { rules: [{ required: true, message: '排序' }],  } ]" />
                </a-form-item>
            </a-form>
        </a-modal>
      </div>

      <!-- 编辑路线 -->
      <div class="editBox">
        <a-modal :visible="visibleEdit" title= "编辑线路" okText='确认' cancel-text="取消" @cancel="editCancel" @ok="editOk" width="800px"  height="800px">
            <!--表单 并将表单的值绑定到this.from-->
            <a-form layout='vertical' :form="formEdit" :label-col="{ span: 6}" :wrapper-col="{ span: 16 }">
                
                <a-form-item label='线路状态:'>
                    <a-select @change="statusChange"  class="memberpx"  v-decorator="['editStatus',{initialValue:'正常', rules: [{ required: true, message: '请选择线路状态!' }] },]">
                      <a-select-option v-for="item in statusData" :key="item.value">
                        {{item.name}}
                      </a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label='线路名称:'>
                    <a-input placeholder="线路名称" v-decorator="[ 'editName', { rules: [{ required: true, message: '线路名称' }],  } ]" />
                </a-form-item>
                <a-form-item label='IP:'>
                    <a-input placeholder="IP" v-decorator="[ 'editIpAdd', { rules: [{ required: true, message: 'IP' }],  } ]" />
                </a-form-item>
                <a-form-item label='地区:'>
                    <a-select style="width:48%;margin-right:4%;" v-model="lineInfo.countrys" @change="areaChanges"  class="memberpx">
                      <a-select-option v-for="item in countryData" :value="item.value+'/'+item.code" :key="item.value">
                        {{item.name}}
                      </a-select-option>
                    </a-select>
                   
                    
                </a-form-item>
                <a-form-item label='支持设备:'>

                  <a-checkbox-group v-model="lineInfo.checkArr" :options="plainOptions" @change="onChangeCheck" />
                </a-form-item>
                
                <a-form-item label='移动端协议端口：'>
                    <a-input v-model="lineInfo.agreePort"  placeholder="移动端协议端" />
                </a-form-item>
                <a-form-item label='PC驱动协议端口：'>
                    <a-input v-model="lineInfo.pcPort"  placeholder="PC驱动协议端口" />
                </a-form-item>
                <a-form-item label='备注'>
                    <a-input v-model="lineInfo.desc" type="textarea" />
                </a-form-item>
                <a-form-item label='排序:'>
                  <a-input placeholder="排序" v-decorator="[ 'editSort', { rules: [{ required: true, message: '排序' }],  } ]" />
                </a-form-item>
                  
            </a-form>
        </a-modal>
      </div>

  </div>
</template>
<script>
function getBase64(img, callback) {

  const reader = new FileReader();

  reader.addEventListener('load', () => callback(reader.result));

  reader.readAsDataURL(img);

}
import common from '@/assets/js/Common'

import API from '@/assets/js/Api'
export default {

  data() {
    return {
      cintrutFlag: false,
      columns: [
        {

          title: '线路状态',
          dataIndex: 'status',
          width: '4%',
          align: 'center',
          scopedSlots: { customRender: 'statusIndex' },
        },
        {
          title: '线路名称',
          dataIndex: 'name',
          width: '10%',
          align: 'center',
          scopedSlots: { customRender: '线路名称' },

        },
        {
          title: 'ip',
          dataIndex: 'ip',
          width: '10%',
          align: 'center',
          scopedSlots: { customRender: 'ip' },

        },
        {
          title: '支持设备',
          dataIndex: 'platform',
          width: '6%',
          align: 'center',
          scopedSlots: { customRender: '支持设备' },

        },
        {
          title: '协议端口',
          dataIndex: 'port',
          width: '4%',
          align: 'center',
          scopedSlots: { customRender: '协议端口' },

        },

        {

          title: '地区',
          dataIndex: 'country',
          width: '5%',
          align: 'center',
          scopedSlots: { customRender: '地区' },

        },
         {

          title: 'PC驱动协议端口',
          dataIndex: 'pc_port',
          width: '6%',
          align: 'center',
          scopedSlots: { customRender: 'PC驱动协议端口' },

        },
         {

          title: '备注',
          dataIndex: 'remark',
          width: '8%',
          align: 'center',
          scopedSlots: { customRender: 'PC驱动协议端口' },

        },
        {

          title: '排序',
          dataIndex: 'sort',
          width: '8%',
          align: 'center',
          

        },

        {
          title: '操作',
          dataIndex: 'index',
          align: 'center',
          width: '8%',
          scopedSlots: { customRender: 'operatindex' },

        },
      ],
      
      plainOptions: ['Android', 'IOS','PC'],
      country:'',
      city:'',

      dataSource: [],
      lineInfo:{
        id:0,
        desc:'',
        pcPort:'',
        agreePort:'',
        countrys:'',
        checkArr:['Android', 'IOS','PC'],
        area: undefined,
        countryInfo:'',
        code:''

      },
      editingKey: '',
      visible: false,   //添加列表开关
      visibleEdit: false,//编辑
      imageUrl: '',  
      countryInfo:{
        countryCode:'',
        countryNameJ:'',
        countrSort:''
      },
      statusData:[
        {name:"正常",value:"正常"},
        {name:"禁用",value:"禁用"},
      ],
      typeData:[
        {name:"非洲路线",value:"非洲路线"},
        {name:"亚洲路线",value:"亚洲路线"},
        {name:"欧洲路线",value:"欧洲路线"},
        {name:"北美路线",value:"北美路线"},
        {name:"大洋路线",value:"大洋路线"},
        {name:"南美路线",value:"南美路线"},
      ],
      countryData:[],
      citytData:[],
      visibleDelete: false,
      form: this.$form.createForm(this),
      formEdit: this.$form.createForm(this),
      pages:1,
      rows:10,
      total:10,
    };
  },
  methods: {
 
 // 终端来源
    courntryChange(value) {
     
      this.lineInfo.countryInfo = value

    },
    conClick(){
      this.cintrutFlag = true;
    },
    conleave(){
      this.cintrutFlag = false;
    },
    areaChange(value){
      this.lineInfo.code=value.split('/')[1]
      console.log(this.lineInfo.code)
    },
    areaChanges(value){
      this.lineInfo.code=value.split('/')[1]
      console.log(this.lineInfo.code)
    },
    sourceChange(){},

    // 线路状态设置
    statusChange(value){
      
    },

    // 支持设备复选框内容
     onChangeCheck(checkedValues) {
      console.log('checked = ', checkedValues);
      this.lineInfo.checkArr = checkedValues
    },
 // 清空搜索
    clearSeach(){
      this.lineInfo={
        lineName:'',
        lineIp:'',
        linePort:'',
        connectName:''
      },
      this.createValue=[]

      this.seach();

    },

    onChangePage(page){
      this.pages = page;
      this.seach();
    },
     //分页查询国家设置
    seach(){
       let opts = {
              url: API.lineList,
              data: {
                name: this.lineInfo.name,
                ip: this.lineInfo.ip,
                port: this.lineInfo.port,
                domain_name: this.lineInfo.domain_name,
                page: this.pages

              },       
        }
        common.query(opts).then(res=>{
          let arr = []
          let result = res.line_list;
          this.total = res.count
          result.forEach(function(v,i){
            arr.push({key: v.id, status: v.status,sort:v.sort, name: v.name, ip: v.ip, platform: v.platform, port: v.port, pc_port: v.pc_port, country: v.country,remark: v.remark});

          })

           this.dataSource = arr;
      },res=>{   
            this.$message.error(res.remark);

      });
    },

    //删除线路
    deleteLine(key){
      let _this = this;
      let opts = {
            url: API.deleteLine,
            data: {
              id: key
            },       
      }
      this.$confirm({
        title: '确定删除此线路？',
        content: '',
        okText: '是的',
        okType: '',
        cancelText: '取消',
        onOk() {
          common.query(opts).then(res=>{
          
            _this.$message.success('删除成功');
            _this.seach();
          },res=>{   
            _this.$message.error(res.remark);
          });
        },
        onCancel() {
          
        },
      });
    },
  // 获取国家列表
  queryCountry(){
     let opts = {
              url: API.queryCountry,
              data: {},       
        }

        common.query(opts).then(res=>{
          var arr = []
          res.forEach(function(v,i){
            arr.push({name:v.name,value:v.name,code: v.code})
          })
          this.countryData = arr;
          console.log(this.countryData)
        },res=>{
            this.$message.error(res.remark);

        })

           
      
  },

    //关闭编辑弹窗

    handleCancel() {

        this.visible = false;

    },
    //点击确认添加
    handleOk() {
        const form = this.form;
        
        
        var ac=this.lineInfo.checkArr
       

        for(var i=0;i<ac.length;i++){
          if(ac[i]=='IOS'){
            ac[i]='iphone'
          }
        }
        var str = ac.toString();
       
        
        let opts = {}
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            if(values.lineStatus == '正常'){
              values.lineStatus = '1'
            }
            if(values.lineStatus == '禁用'){
              values.lineStatus ='-2'
            }
            
              
            opts = {
                url: API.addLine,
                data: {
                  status: values.lineStatus,
                  name: values.names,
                  cate: values.lineType,
                  ip: values.ipAdds,
                  country: this.lineInfo.code,
                  port: this.lineInfo.agreePort,
                  pc_port:this.lineInfo.pcPort,
                  remark:this.lineInfo.desc,
                  platform: str.toLowerCase(),
                  sort: values.countrSort
                  
                },       
            } 
            common.query(opts).then(res=>{
              this.$message.success('添加成功');
              this.visible = false; 
              form.resetFields();
              this.seach()
            },res=>{   
                this.$message.error(res.remark);

            });

           
        });
      
       
    },



    //关闭编辑弹窗

    editCancel() {

        this.visibleEdit = false;

    },

    // 编辑列表
    editList(key){
      
      this.visibleEdit = true;
      this.lineInfo.id = key
      
       let opts = {
              url: API.editLine,
              data: {
                id: key
              },       
        }

        common.query(opts).then(res=>{
          if(res.status==1){
            res.status = '正常'
          }else{
            res.status = '禁用'
          }
          this.formEdit.setFieldsValue({
              editStatus: res.status,
              editName: res.name,
              editIpAdd: res.ip,
              editSort: res.sort,

          })
          
          this.lineInfo={
            countrys: res.country_name,
            code: res.code,
            agreePort: res.port, 
            desc: res.remark,
            checkArr: res.platform.split(","),
            pcPort: res.pc_port,
            id: res.id,

          }
      },res=>{   
            this.$message.error(res.remark);

      });
    },

    // 提交编辑内容
  
    editOk() {
        const form = this.formEdit;
        let opts = {}
        var ac=this.lineInfo.checkArr
        for(var i=0;i<ac.length;i++){
          if(ac[i]=='IOS'){
            ac[i]='iphone'
          }
        }
        var str = ac.toString();
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            if(values.editStatus == '正常'){
              values.editStatus = 1
            }else{
              values.editStatus = 2
            }
            
            opts = {
                url: API.addLine,
                data: {
                  id: this.lineInfo.id,
                  status: values.editStatus,
                  name: values.editName,
                  ip: values.editIpAdd,
                  country: this.lineInfo.code,
                  port: this.lineInfo.agreePort,
                  pc_port:this.lineInfo.pcPort,
                  remark:this.lineInfo.desc,
                  platform: str.toLowerCase(),
                  sort: values.editSort,
                  
                },       
            }  
            
            common.query(opts).then(res=>{
              this.$message.success('修改成功');
              this.visibleEdit = false; 
              form.resetFields();  
              this.seach()
            },res=>{   
              this.$message.error(res.remark);
            });   
           
        });
       
        
    },

    deleteConfigure(key){
      console.log(key)
    },


  // 上传图片前处理
    beforeUpload(file) {

        console.log(file)

        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

        if (!isJpgOrPng) {

          this.$message.error('You can only upload JPG file!');

        }

        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isLt2M) {

          this.$message.error('Image must smaller than 2MB!');

        }

        return isJpgOrPng && isLt2M;

    },

     //上传图片

    uploadImgChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (info.file.status === 'done') {
        getBase64(info.file.originFileObj, imageUrl => {
          this.imageUrl = imageUrl;
          this.loading = false;

        });

      }

    },

     // 点击添加国家按钮

    AddConfigure(key) {

      this.visible= true;
        
      this.lineInfo={
          countryInfo:'',
          agreePort:'',
          pcPort:'',
          desc:'',
          checkArr:[]
      }
    },

},

  mounted(){

     this.seach();
     this.queryCountry();

  },
  beforeCreate() {
          //创建表单
    this.form = this.$form.createForm(this, { name: 'form_in_modal' });
    this.formEdit = this.$form.createForm(this, { name: 'form_in_modals' });

  },
};
</script>
<style lang="less">

.version{padding-top: 20px;

  .memberpx{width: 150px!important;margin-right: 20px;}
  
  .clear{margin-top: 20px;}

  .queryContent{margin-top: 30px;}

  .operatTab a{padding: 2px 10px;background: #1890ff;color: #fff;border-radius: 5px;display: inline-block;margin-right: 8px;}
   .countryInt{width:550px;height: auto;position: absolute;z-index: 9999;opacity: 1;background: #fff;border: 1px solid #ccc;border-radius: 4px;top: 25px;
    left: 160px;font-size: 14px;padding: 20px;box-shadow: 6px 6px 5px #888888;}
    .areaInt{width: 300px;position: absolute;right:0;top: 20%;text-align: left;}

}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label{text-align: right!important;}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label{text-align: right!important;}
.queryTop{margin-bottom: 20px;}

</style>
