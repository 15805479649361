<template>
  <div class="version">
    <div class="queryTop">

       <a-button type="primary" style="margin-right: 10px;margin-bottom:30px;"  icon="plus" @click="AddConfigure()">添加国家</a-button>
       <span @mouseenter="conClick" @mouseleave="conleave">
         <a-icon type="question-circle" style="font-size:22px;cursor: pointer;color:#888;"  />
       </span>

    </div>

    <div class="countryInt" v-if="cintrutFlag">
      <p>1、该列表可查看、编辑所有国家信息。每添加一个国家，该列表就新增一行国家信息，客户端“线路节点”页面就新增一个相应的国家/地区。</p>
      <p>2、在客户端的对应显示如下：</p>
      <img src="../assets/img/2.png" alt="" >
    </div>

    

    <!-- 列表内容 -->
    <a-table bordered :data-source="dataSource" :columns="columns" :pagination="false">
       <a slot="name" slot-scope="text">{{ text }}</a>
          <template slot="operatindex" slot-scope="text, record">
            <div class="editable-row-operations">
              <span class="operatTab">
                
                <a :disabled="editingKey !== ''" @click="() => editList(record.key)">编辑</a>
                <a :disabled="editingKey !== ''" @click="() => deleteConfigure(record.key)">删除</a>
              </span>
            </div>
          </template>
          <template slot="imgIndex" slot-scope="text,record">
            <div class="editable-row-operations">
              <span class="operatTab" >
                <img :src="record.img" alt="" style="width: 50px;">
              </span>
            </div>
          </template>
          <template>
            <span slot="customName" style="position: relative;" >
              国家别名（用于搜索）
                <a-icon type="question-circle" @mouseenter="areaClick" @mouseleave="areaLeave" style="font-size:20px;cursor: pointer;color:#888;position: absolute;"  />
                <div class="countryInt areaInt" v-if="areaFlag">
                  <p>1、内部人员为了便于快速搜索而对国家进行的自定义的命名。</p>
                  <p>2、举例：若给某一国家A的别名命名为“111”，则搜索国家时输入“111”，即可搜到国家A。</p>
                  
                </div>
            </span>
          </template>
          <template>
            <span slot="sortName" style="position: relative;" >
              排序&nbsp;
                <a-icon type="question-circle" @mouseenter="sortClick" @mouseleave="sortLeave" style="font-size:20px;cursor: pointer;color:#888;position: absolute;"  />
                <div class="countryInt" v-if="sortFlag" style="left: -300px;text-align: left;width: 450px;">
                  <p>1、表中排序以逆序自上而下排列。</p>
                  <p>2、表中的排列顺序与客户端的显示顺序相对应</p>
                  <p>举例：若表中为：第一行美国，第二行德国，第三行澳大利亚。第四行菲律宾。</p>
                  <p>则客户端对应如下，为：第一个美国，第二个德国，第三个澳大利亚，第四个菲律宾。</p>
                  <img src="../assets/img/2.png" alt="" style="width: 300px">
                  
                </div>
            </span>
          </template>
          <template>
            <span slot="oprateName" style="position: relative;" >
              操作&nbsp;
              
                <a-icon type="exclamation-circle" @mouseenter="oprateClick" @mouseleave="oprateLeave" style="font-size:20px;cursor: pointer;color:#888;position: absolute;"  />
                <div class="countryInt" v-if="oprateFlag" style="left: -200px;text-align: left;width: 260px;">
                  <p>注意：当删除某一国家后，在线路配置中，该国家下对应的线路也会被删除。</p>
                  
                  
                </div>
            </span>
          </template>
    </a-table>
     <!-- 分页 -->
    <div class="pagein">
        <a-pagination v-model="pages" :total="total" :page-size="rows" show-less-items
            :show-total="total => `共${total}条数据`" @change="onChangePage(pages)" />
    </div>

    <!-- 添加弹窗 -->
      <div class="editBox">
        <a-modal :visible="visible" title="添加国家" okText='确认' cancel-text="取消" @cancel="handleCancel" @ok="handleOk" width="800px" height="800px">
          <!--表单 并将表单的值绑定到this.from-->
          <a-form layout='vertical' :form="form" :label-col="{ span: 8}" :wrapper-col="{ span: 14 }">
            <!--每一项元素-->
            <a-form-item label='国家编码:'>
              <a-input placeholder="国家编码（大写/缩写）"  v-decorator="[ 'countryCode', { rules: [{ required: true, message: '国家编码（大写/缩写）' }],  } ]" />
            </a-form-item>
            <a-form-item label='国家名称(简体):'>
              <a-input placeholder="国家名称(简体)" v-decorator="[ 'countryNameJ', { rules: [{ required: true, message: '国家名称(简体)' }],  } ]" />
            </a-form-item>
            <a-form-item label='国家别名(用于搜索):'>
              <a-input placeholder="国家别名(用于搜索)" v-decorator="[ 'countrAlias', { rules: [{ required: true, message: '国家别名(用于搜索)' }],  } ]" />
            </a-form-item>
            <a-form-item label='国旗:'>
              <a-upload name="file" list-type="picture-card" v-decorator="[ 'uploadImg', { rules: [{ required: true, message: '请上传图片' }],  } ]"  class="avatar-uploader" :show-upload-list="false" action="https://api.zhengxingjiufu.com/admin/upload_img" :before-upload="beforeUpload" @change="uploadImgChange">
               <div class="ant-upload-text">选择上传图片</div>
              </a-upload>
              <div class="uploadImgs" v-if="imageUrl">
                <img :src="imageUrl" alt="avatar" style="width:130px;height:80px;"/>
              </div>
            </a-form-item>
            <a-form-item label='排序:'>
              <a-input placeholder="排序" v-decorator="[ 'countrSort', { rules: [{ required: true, message: '国家别名(用于搜索)' }],  } ]" />
            </a-form-item>
          </a-form>
        </a-modal>
      </div>

    <!-- 编辑弹窗 -->
      <div class="editBox">
        <a-modal :visible="visibleEdit" title="信息" okText='确认' cancel-text="取消" @cancel="editCancel" @ok="editOk" width="700px" height="800px">
          <!--表单 并将表单的值绑定到this.from-->
          <a-form layout='vertical' :form="formEdit" :label-col="{ span: 8}" :wrapper-col="{ span: 14 }">
            <!--每一项元素-->
            <a-form-item label='国家编码:'>
              <a-input placeholder="国家编码（大写/缩写）"  v-decorator="[ 'editCode', { rules: [{ required: true, message: '国家编码（大写/缩写）' }],  } ]" />
            </a-form-item>
            <a-form-item label='国家名称(简体):'>
              <a-input placeholder="国家名称(简体)" v-decorator="[ 'editNameJ', { rules: [{ required: true, message: '国家名称(简体)' }],  } ]" />
            </a-form-item>
            <a-form-item label='国家别名(用于搜索):'>
              <a-input placeholder="国家别名(用于搜索)" v-decorator="[ 'editAlias', { rules: [{ required: true, message: '国家别名(用于搜索)' }],  } ]" />
            </a-form-item>
            <a-form-item label='国旗:'>
              <a-upload name="file" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" action="https://api.zhengxingjiufu.com/admin/upload_img" :before-upload="beforeUpload" @change="uploadImgChange">
               <div class="ant-upload-text">选择上传图片</div>
              </a-upload>
              <div class="uploadImgs"  v-if="editUrl">
                <img :src="editUrl" alt="avatar" style="width:130px;height:80px;"/>
              </div>
            </a-form-item>
            <a-form-item label='排序:'>
              <a-input placeholder="排序" v-decorator="[ 'editSort', { rules: [{ required: true, message: '排序' }],  } ]" />
            </a-form-item>
          </a-form>
        </a-modal>
      </div>


  </div>
</template>
<script>
function getBase64(img, callback) {

  const reader = new FileReader();

  reader.addEventListener('load', () => callback(reader.result));

  reader.readAsDataURL(img);

}
import common from '@/assets/js/Common'

import API from '@/assets/js/Api'
export default {

  data() {
    return {
      columns: [
        {
          title: '国家编码',
          dataIndex: 'code',
          width: '7%',
          align: 'center',
          
        },
        {
          title: '国家名称(简体)',
          dataIndex: 'name',
          width: '10%',
          align: 'center',
        },
        {
          
          dataIndex: 'alias',
          width: '10%',
          align: 'center',
          slots: { title: 'customName'}
        },
        {
          title: '国旗',
          dataIndex: 'img',
          width: '10%',
          align: 'center',
          scopedSlots: { customRender: 'imgIndex' },
        },
        {
          
          dataIndex: 'sort',
          width: '5%',
          align: 'center',
          slots: { title: 'sortName'}

        },
        {
         
          dataIndex: 'index',
          align: 'center',
          width: '5%',
          slots: { title: 'oprateName'},
          scopedSlots: { customRender: 'operatindex' },

        },
      ],
      cintrutFlag: false,
      sortFlag: false,
      oprateFlag: false,
      dataSource: [],
      editingKey: '',
      contryId:"0",
      visible: false,   //添加列表开关
      visibleEdit: false,//编辑
      imageUrl: '',  
      editUrl:'',
      countryInfo:{
        countryCode:'',
        countryNameJ:'',
        countrSort:'',
        editCode:'',
        editNameJ:'',
        editSort:'',
        editAlias:''
      },
      form: this.$form.createForm(this),
      formEdit: this.$form.createForm(this),
      pages:1,
      rows:10,
      total:10,
      imgSrc:'',
      areaFlag: false
    };
  },
  methods: {
    onCellChange(key, dataIndex, value) {
      const dataSource = [...this.dataSource];
      const target = dataSource.find(item => item.key === key);
      if (target) {
        target[dataIndex] = value;
        this.dataSource = dataSource;
      }
    },
    onDelete(key) {
      const dataSource = [...this.dataSource];
      this.dataSource = dataSource.filter(item => item.key !== key);
    },

     //分页查询国家设置
    seach(){
       let opts = {
              url: API.countryList,
              data: {
                page: this.pages
              },       
        }

        common.query(opts).then(res=>{
          let arr = []
          let result = res.ccountry_list;
          this.total = res.count
          result.forEach(function(v,i){
            arr.push({key: v.id, code: v.code, name: v.name, alias: v.alias, img: v.img, sort: v.sort});

          })

           this.dataSource = arr;
      },res=>{   
            this.$message.error(res.remark);

      });
    },
    conClick(){
      this.cintrutFlag = true;
    },
    conleave(){
      this.cintrutFlag = false
    },
    areaClick(){
      this.areaFlag = true;
    },
    sortClick(){
      this.sortFlag = true
    },
    sortLeave(){
      this.sortFlag = false
    },
    oprateClick(){
      this.oprateFlag = true
    },
    oprateLeave(){
      this.oprateFlag = false
    },
    areaLeave(){
      this.areaFlag = false;
    },
    onChangePage(page){
      this.pages = page;
      this.seach();
    },

     // 删除
    
    deleteConfigure(key){
      let _this = this;
      let opts = {
            url: API.delectCountry,
            data: {
              id: key
            },       
      }
      this.$confirm({
        title: '确定删除？',
        content: '',
        okText: '是的',
        okType: '',
        cancelText: '取消',
        onOk() {
          common.query(opts).then(res=>{
          
            _this.$message.success('删除成功');
            _this.seach();
          },res=>{   
            _this.$message.error(res.remark);
          });
        },
        onCancel() {
          
        },
      });
    },

    //关闭添加弹窗

    handleCancel() {

        this.visible = false;

    },

     //关闭编辑弹窗

    editCancel() {

        this.visibleEdit = false;

    },

    //点击添加确认添加
    handleOk() {
        const form = this.form;
        let opts = {}
        form.validateFields((err, values) => {
            if (err) {
                return;
            }            
            opts = {
                url: API.AddCountry,
                data: {
                  id:'',
                  code: values.countryCode,
                  name: values.countryNameJ,
                  sort: values.countrSort,
                  alias: values.countrAlias,
                  img: this.imgSrc
                },       
              }
        });
        common.query(opts).then(res=>{
          this.$message.success("添加成功");
          this.visible = false;
          form.resetFields();
          this.seach()
      },res=>{   
          this.$message.error(res.remark);

      });
    },

    // 编辑确认按钮
    editOk(){
        const form = this.formEdit;
        
            let opts = {}
            form.validateFields((err, values) => {
                if (err) {
                    return;
                }
                
                opts = {
                    url: API.AddCountry,
                    data: {
                      id: this.contryId,
                      code: values.editCode,
                      name: values.editNameJ,
                      sort: values.editSort,
                      alias: values.editAlias,
                      img: this.imgSrc
                    },       
                  }
            });
            common.query(opts).then(res=>{
              this.$message.success('修改成功');
              this.visibleEdit = false;  
              form.resetFields();  
            this.seach()
          },res=>{   
            this.$message.error(res.remark);
          });
    },


    //关闭编辑弹窗

    editCancel() {

        this.visibleEdit = false;

    },


    // 查询编辑指定国家内容
    editList(key){
      this.contryId = key
      this.visibleEdit = true;
      this.editUrl = ''
       let opts = {
              url: API.queryEditCountry,
              data: {
                id: key
              },       
        }
        // return
        common.query(opts).then(res=>{
         this.editUrl = res.img;
         this.imgSrc = res.img
          this.formEdit.setFieldsValue({
              editCode: res.code,
              editNameJ: res.name,
              editAlias: res.alias,
              editSort: res.sort,
              

          })
      },res=>{   
            this.$message.error(res.remark);
        
      });
    },


  // 上传图片前处理
    beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

        if (!isJpgOrPng) {

          this.$message.error('You can only upload JPG file!');

        }

        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isLt2M) {

          this.$message.error('Image must smaller than 2MB!');

        }

        return isJpgOrPng && isLt2M;

    },

     //上传图片

    uploadImgChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (info.file.status === 'done') {
        this.imgSrc = info.file.response.data.path_url;
        getBase64(info.file.originFileObj, imageUrl => {
          this.imageUrl = imageUrl;         
          this.editUrl = imageUrl
          this.loading = false;

        });

      }

    },

     // 点击添加国家按钮

      AddConfigure(key) {
        this.imageUrl = ''
        this.visible= true;
      },

},

  mounted(){

     this.seach()
     

  },
  beforeCreate() {
          //创建表单
    this.form = this.$form.createForm(this, { name: 'form_in_modal' });
    this.formEdit = this.$form.createForm(this, { name: 'form_in_modals' });
  },
};
</script>
<style lang="less">

.version{padding-top: 20px;position:relative;

  .memberpx{width: 150px!important;margin-right: 20px;}

  .clear{margin-top: 20px;}

  .queryContent{margin-top: 30px;}

  .operatTab a{padding: 2px 10px;background: #1890ff;color: #fff;border-radius: 5px;display: inline-block;margin-right: 8px;}
  .countryInt{width:550px;height: auto;position: absolute;z-index: 9999;opacity: 1;background: #fff;border: 1px solid #ccc;border-radius: 4px;top: 46px;
    left: 160px;font-size: 14px;padding: 20px;box-shadow: 6px 6px 5px #888888;}
    .areaInt{width: 300px;position: absolute;right:0;top: 20%;text-align: left;}

}


.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label{text-align: right!important;}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label{text-align: right!important;}


</style>
