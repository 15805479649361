<template>
  <div class="err">
    <h1>This is an err 404 page</h1>
  </div>
</template>
<script>


export default {
  name: 'Err',
  components: {
  }
}
</script>