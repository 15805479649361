<template>
  <div class="member">

    <!-- 搜索条件 -->
    <div class="queryTop" style="position: relative;">
      
      <a-input v-model="searchInfo.email" class="memberpx" placeholder="用户邮箱" />
      <a-select @change="sourceChange" v-model="searchInfo.userType"  class="memberpx" placeholder="用户状态">
        <a-select-option v-for="item in terminalSourceData" :value="item.value" :key="item.value">
          {{item.name}}
        </a-select-option>
      </a-select>
      <a-select v-model="searchInfo.equipment" placeholder="设备终端"   class="memberpx">
        <a-select-option v-for="item in equipmentData" :value="item.value" :key="item.value">
          {{item.name}}
        </a-select-option>
      </a-select>
      <a-select default-value="用户所在地"  v-model="searchInfo.country"  class="memberpx" placeholder="用户所在地">
        <a-select-option v-for="item in countryData" :value="item.value" :key="item.value">
          {{item.name}}
        </a-select-option>
      </a-select>


      <a-range-picker @change="timeChange" style="margin-right:20px;width: 20%"  :locale="locale"  :value="createValue"
      :show-time="{ hideDisabledOptions: true, defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')], }" format="YYYY-MM-DD HH:mm:ss"/> 
      <!-- <a-date-picker :locale="locale" :showTime="{ initialValue: moment('00:00:00', 'HH:mm:ss') }" format="YYYY-MM-DD HH:mm:ss" placeholder="开始时间" @change="startChange" @ok="startOk"  style="margin-right: 1%;"/>
      <a-date-picker :locale="locale" show-time placeholder="结束时间" @change="finishChange" @ok="finishOk" style="margin-right: 1%;"/> -->
      <a-button type="primary" icon="search" @click="seach">搜索</a-button>
      <a-button icon="close" style="margin-left:20px;margin-right: 10px;" @click="clearSeach">清空搜索</a-button>
      <a-icon type="question-circle" @mouseenter="clearsClick" @mouseleave="clearleave" style="font-size:20px;cursor: pointer;color:#888;"/>
      <div class="countryInt areaInt" v-if="clearsFlag" style="left: 60%;top: 40px;">
          <p>1、该列表可查看所有用户信息。客户端和官网每注册一个用户，该列表就新增一行用户信息。</p>
          <p>2、搜索栏可多字段控制变量筛选订单详情。 </p>
          <p>3、开始日期~结束日期栏</p>
          <div>
            <p>（1）此处指的是：用户的注册日期。</p>
            <p>（2）通过对时间进行限定，从而搜索出在一定时间内注册的用户。</p>
          </div>
      </div>
      <div class="clear">
        <a-button icon="download" @click="output" style="float: left;background:red;" :style="{ fontSize: '16px', color: '#fff' }" >导出</a-button>
        <span class="allCount">共有{{total}}条数据</span>
        
        
      </div>
    </div>


    <!-- 搜索内容列表 -->
    <div class="queryContent">

         <!-- 列表内容 -->
    <a-table bordered :data-source="dataSource" :columns="columns" :pagination="false">
       <a slot="name" slot-scope="text">{{ text }}</a>
          <template slot="statusIndex" slot-scope="text,record">
            <div class="editable-row-operations" >
              <span class="operatTab" v-if="record.status!='1'" style="color: red;">
                禁用
              </span>
              <span class="operatTab" v-else>
                正常
              </span>
            </div>
          </template>

           <template slot="platformIndex" slot-scope="text,record">
            <div class="editable-row-operations" >
              <span class="operatTab" v-if="record.platform=='pc'">
                PC
              </span>
              <span class="operatTab" v-if="record.platform=='android'">
                Android
              </span>
              <span class="operatTab" v-if="record.platform=='iphone'">
                IOS
              </span>
            </div>
          </template>

           <template>
            <span slot="customName"  style="position: relative;">
                用户状态
                <a-icon type="question-circle" @mouseenter="areaClick" @mouseleave="arealeave" style="font-size:20px;cursor: pointer;color:#888;position: absolute;left: 110%;"  />
                <div class="countryInt areaInt" v-if="areaFlag" >
                  <p>1、用户类型包括：普通用户和会员用户。</p>
                  <p>2、普通用户指：仅注册未付费的用户，显示如下</p>
                  <img src="../assets/img/4.png" alt="" style="height: 100px;margin-bottom: 20px;">
                  <p>会员用户指：付费用户，显示如下</p>
                  <img src="../assets/img/6.png" alt="" style="height: 100px;">

                </div>
            </span>
          </template>

          <template>
            <span slot="platName"  style="position: relative;">
                终端
                <a-icon type="question-circle" @mouseenter="platClick" @mouseleave="platleave" style="font-size:20px;cursor: pointer;color:#888;position: absolute;left: 110%;"  />
                <div class="countryInt areaInt" v-if="platsFlag" style="left: 60px;">
                  <p>1、设备终端指：用户注册时使用的设备终端。</p>
                  <p>2、设备终端包括：PC、Android、iOS</p>
                  

                </div>
            </span>
          </template>
          <template slot="operatindex" slot-scope="text, record">
            <div class="editable-row-operations">
              <span class="operatTab">
                
                <a :disabled="editingKey !== ''" @click="() => editList(record.key)">编辑</a>
              </span>
            </div>
          </template>
    </a-table>

    <!-- 分页 -->
    <div class="pagein">
        <a-pagination v-model="pages" :total="total" :page-size="rows" show-less-items
            :show-total="total => `共${total}条数据`" @change="onChangePage(pages)" />
    </div>
    </div>
     <!-- 编辑弹窗 -->
      <div class="editBox">
        <a-modal :visible="visibleEdit" title="查看会员信息" okText='确认' cancel-text="取消" @cancel="editCancel" @ok="editOk" width="600px" height="800px">
          <!--表单 并将表单的值绑定到this.from-->
          <a-form layout='vertical' :form="formEdit" :label-col="{ span: 3}" :wrapper-col="{ span: 14 }">
            <!--每一项元素-->
            <a-form-item label='状态:'>
              <a-select  style="" @change="sourceChange" v-decorator="[ 'memberStatus', {initialValue:'正常', rules: [{ required: true, message: '' }],  } ]"  class="memberpx">
                <a-select-option v-for="item in memberData" :value="item.value" :key="item.value">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
        </a-modal>
      </div>
  </div>
</template>

<script>

import moment from 'moment';
import common from '@/assets/js/Common'
import API from '@/assets/js/Api'
import Modals from '../components/Modal.vue'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
moment.locale('zh-cn')
export default {
  name: 'Member',
  components:{
   Modals
  },
  data() {
    return {
      columns : [
        {title:'用户ID',dataIndex:'ID',width:'7%',align:'center',scopedSlots:{customRender:'ID'}},
        {title:'用户来源',dataIndex:'OEM',width:'8%',align:'center',scopedSlots:{customRender:'OEM'}},	
        {dataIndex:'is_vip',width:'8%',align:'center',slots:{title:'customName'}},
        {title:'Email',dataIndex:'Email',width:'10%',align:'center',scopedSlots:{customRender:'Email'}},
        {dataIndex:'platform',width:'5%',align:'center',slots:{title:'platName'},scopedSlots:{customRender:'platformIndex'}},
        {title:'用户所在地',dataIndex:'registerArea',width:'12%',align:'center',scopedSlots:{customRender:'用户所在地'},},
        {title:'注册时间',dataIndex:'registerTime',width:'10%',align:'center',scopedSlots:{customRender:'注册时间'}},
        {title:'最后登录时间',dataIndex:'last_log_time',width:'10%',align:'center',scopedSlots:{customRender:'最后登录时间'}},
        {title:'最后连接时间',dataIndex:'last_line_time',width:'10%',align:'center',scopedSlots:{customRender:'最后连接时间'}},
        {title:'用户状态',dataIndex:'status',width:'10%',align:'center',scopedSlots:{customRender:'statusIndex'},},
        {title: '操作',dataIndex: 'index',align: 'center',width: '8%',scopedSlots: { customRender: 'operatindex' },

        },
      ],
      
      areaFlag:false,
      platsFlag: false,
      clearsFlag: false,
      dataSource: [],
      visible: false,    //编辑flag
      ModalText: 'Content of the modal',
      delectVisible: false,   //删除flag
      confirmLoading: false,
      idMember: 0,
      countryData:[],
      oemData:[{name: 'OEM', value: 'OEM'}],
      equipmentData:[
        {name: 'Android', value: 'Android'},
        {name: 'IOS', value: 'IOS'},
        {name: 'PC', value: 'PC'},

      ],
      visibleEdit:false,
      selectedRowKeys: [], 
      loading: false,
      searchInfo: {
        
        equipment: undefined,
        userType: undefined,
        oemSelect:'OEM',
        country:undefined,
        email:'',

        phoneNum:'',
        startTime:'',
        endTime:''
      },
      dateTime:[], //时间段
      locale,

      terminalSourceData:[
        {name: '会员用户', value: '会员用户'},
        {name: '普通用户', value: '普通用户'}
      ],
      memberData:[
        {name: '正常', value: '正常'},
        {name: '禁用', value: '禁用'}
      ],
      editingKey: '',
      createValue: [],
      pages:1,
      rows:10,
      total:10,
      formEdit: this.$form.createForm(this),
      queryStatus:'1',
      memId:''

    };
  },

  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },

  beforeCreate() {
    //创建表单
    this.form = this.$form.createForm(this, { name: 'form_in_modal' });
    this.formEdit = this.$form.createForm(this, { name: 'form_in_modals' });

  },
  
    methods: {
      moment,
       //查询国家设置
    seach(){
      var plat = this.searchInfo.equipment;
      var member = this.searchInfo.userType;
      var em = this.searchInfo.email
      if(em){
        em=common.trimSpace(this.searchInfo.email)
      }
      if(plat == 'IOS'){
        plat = 'iphone'
      }
      if(plat == 'Android'){
        plat = 'android'
      }
      if(plat == 'PC'){
        plat = 'pc'
      }
      if(member == '普通用户'){
        member = '0'
      }
      if(member == '会员用户'){
        member = '1'
      }
      
       let opts = {
              url: API.memberList,
              data: {
                start:this.searchInfo.startTime,
                end: this.searchInfo.endTime,
                email: em,
                is_vip:member,
                device: plat,
                page:this.pages,
                country: this.searchInfo.country
              },       
        }

        common.query(opts).then(res=>{
          let arr = []
          let result = res.userlist;
          this.total = res.count
         
          result.forEach(function(v,i){
            if(v.is_vip == 1){
              v.is_vip = '会员用户'
            }
            if(v.is_vip ==0){
              v.is_vip = '普通用户'
            }
            arr.push({key: v.id, ID: v.id, OEM: v.oem, is_vip: v.is_vip, Email: v.email, platform: v.platform, registerArea: v.country, registerTime: v.reg_time, last_log_time: v.last_log_time,status:v.status,last_line_time: v.last_line_time});

          })
          
           this.dataSource = arr;
      },res=>{   

      });
    },
    // 编辑确认按钮
    editOk(){
        const form = this.formEdit;
        

            let opts = {}
            form.validateFields((err, values) => {
                if (err) {
                    return;
                }
                console.log(values.memberStatus)
              
                if(values.memberStatus=='正常'){
                  this.queryStatus = '1'
                }
                if(values.memberStatus=='禁用'){
                  this.queryStatus = '-2'
                }
                
                
                opts = {
                    url: API.updataMember,
                    data: {
                      id: this.memId,                    
                      status: this.queryStatus,
                     
                     
                    },       
                  }
            });
            
            common.query(opts).then(res=>{
              this.$message.success('修改成功');
              form.resetFields();
              this.visibleEdit = false;    
              
            this.seach()
          },res=>{   
            this.$message.error(res.remark);
          });
    },
    //关闭编辑弹窗

    editCancel() {

        this.visibleEdit = false;

    },
     // 编辑列表
    editList(key){
      
      this.visibleEdit = true;
     
      
       let opts = {
              url: API.memberEdit,
              data: {
                id: key
              },       
        }

        common.query(opts).then(res=>{
          
          this.memId = res.id
          if(res.status=='1'){
            res.status = '正常'
          }
          if(res.status =='-2'){
            res.status = '禁用'
          }
          this.formEdit.setFieldsValue({
              memberStatus: res.status,
             

          })
          
          
      },res=>{   
            this.$message.error(res.remark);

      });
    },
    areaClick(){
      this.areaFlag = true;
    },
    arealeave(){
      this.areaFlag = false;
    },
    platClick(){
      this.platsFlag = true;

    },
    platleave(){
      this.platsFlag = false;

    },
    clearsClick(){
      this.clearsFlag = true;
    },
    clearleave(){
      this.clearsFlag = false;
    },
    onChangePage(page){
      console.log(page)
      this.pages = page;
      this.seach();
    },
      range(start, end) {
      
        const result = [];
        for (let i = start; i < end; i++) {
          result.push(i);
        }
      
        return result;
      },

      //显示编辑弹窗
        showModal() {
            this.visible = true;
        },
        //关闭编辑弹窗
        handleCancel() {
            this.visible = false;
        },
        //点击确认
        handleOk() {
            const form = this.form;
            form.validateFields((err, values) => {
                if (err) {
                    return;
                }
                console.log('form 表单内容: ', values);
                form.resetFields();
                this.visible = false;
            });
        },

      handleTableChanges(page, pageSize) {
        this.search.pageSize=page.pageSize;
        this.search.PageNumber=page.current;
        this.searchclick();
      
      },
       // 获取国家列表
  queryCountry(){
     let opts = {
              url: API.memberContry,
              data: {},       
        }

        common.query(opts).then(res=>{
          var arr = []
          res.forEach(function(v,i){
            arr.push({name:v.country,value:v.country})
          })
          this.countryData = arr;

          
        })
  },
      //获取时间段
      timeChange(date){ 
        
        if(date){
          this.createValue = date;
          this.searchInfo.startTime = date[0].format('YYYY-MM-DD HH:mm:ss');
          this.searchInfo.endTime = date[1].format('YYYY-MM-DD HH:mm:ss');

        }
      },
      disabledDate(current) {
        return current && current < moment().endOf('day');
      },
      disabledDateTime() {
        return {
          disabledHours: () => this.range(0, 24).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        };
      
    },

    disabledRangeTime(_, type) {
      if (type === 'start') {
        return {
          disabledHours: () => this.range(0, 60).splice(4, 20),
          disabledMinutes: () => this.range(30, 60),
          disabledSeconds: () => [55, 56],
        };
      }
      return {
        disabledHours: () => this.range(0, 60).splice(20, 4),
        disabledMinutes: () => this.range(0, 31),
        disabledSeconds: () => [55, 56],
      };
    },

    // 终端来源
    sourceChange(value) {
      
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.find(item => key === item.key);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },

    // 编辑操作功能
    edit(key) {
      let _this = this;
      this.idMember=key;
      this.visible= true
      console.log(this.idMember)
      const newData = [...this.data];   //请求接口的数据
      newData.forEach((item,index)=>{
        if(key == index){
          item.editable=true;
          _this.data = newData
        }
      })
    },
  
    // 导出方法
    output(){
      
       
      var plat = this.searchInfo.equipment;
      var member = this.searchInfo.userType;

      if(plat == 'IOS'){
        plat = 'iphone'
      }
      if(plat == 'Android'){
        plat = 'android'
      }
      if(plat == 'PC'){
        plat = 'pc'
      }
      if(member == '普通用户'){
        member = '0'
      }
      if(member == '会员用户'){
        member = '1'
      }
        if(!this.searchInfo.startTime || !this.searchInfo.endTime){
          this.$message.warning("请选择开始和结束时间")
          return
        }
      console.log(this.searchInfo.equipment)
       let opts = {
              url: API.memberList,
              type: 'GET',
              responseType: 'blob',
              data: {
                start:this.searchInfo.startTime,
                end: this.searchInfo.endTime,
                email: this.searchInfo.email,
                is_vip:member,
                device: plat,
                page:this.pages,
                execl: '1'
              },       
        }

        //alert(22222222222222)
        window.open(opts.url+'?start='+this.searchInfo.startTime+'&end='+this.searchInfo.endTime+'&email='+this.searchInfo.email+'&is_vip='+member+'&device='+plat+'&page='+this.pages+'&execl=1')

      //   common.query(opts).then(res=>{
      //     // let fileName = '会员列表.xls'
      //     // let tableData = res.data
      //     // let blob = new Blob([tableData])//创建Blob对象,Blob(blobParts[, options])blobParts： 数组类型， 数组中的每一项连接起来构成Blob对象的数据
      //     // let downloadElement = document.createElement('a')//创建dom
      //     // let href = window.URL.createObjectURL(blob) //创建下载链接
      //     // downloadElement.href = href
      //     // downloadElement.download = fileName //下载后文件名
      //     // document.body.appendChild(downloadElement)//添加创建的节点
      //     // downloadElement.click() //点击下载
      //     // document.body.removeChild(downloadElement) //下载完成移除元素
      //     // window.URL.revokeObjectURL(href) //释放掉blob对象
      //     window.open()
          
      // },res=>{   

      // });

            
    },

    // 清空搜索
    clearSeach(){
      this.searchInfo={
          equipment: undefined,
          phoneNum:'',
          startTime:'',
          endTime:''
        }
      this.createValue=[];
      this.seach();

    },
    start() {
      this.loading = true;
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },

    
  },
   mounted(){
     this.seach();
     this.queryCountry();
    }
}
</script>
<style scoped lang="less">
.member{padding-top: 20px;
  .memberpx{width:10%!important;margin-right: 20px;}
  .clear{margin-top: 20px;height:80px;clear: both;overflow: hidden;}
  .queryContent{margin-top: 30px;}
  .operatTab a{padding: 2px 10px;background: #1890ff;color: #fff;border-radius: 5px;display: inline-block;margin-right: 8px;}
  .allCount{    display: inline-block;padding-top: 5px;margin-left: 10px;}
   .countryInt{width:400px;height: auto;position: absolute;z-index: 9999;opacity: 1;background: #fff;border: 1px solid #ccc;border-radius: 4px;top: 46px;
    left: 92px;font-size: 14px;padding: 20px;box-shadow: 6px 6px 5px #888888;}
    .areaInt{position: absolute;right:0;top: 20%;text-align: left;}
}
  
</style>
