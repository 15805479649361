<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// import  'assets/less/global.less';
export default {
  name: "app",
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
/* .ant-modal-content{height: 650px!important;;overflow-y: scroll!important;}
 */
 .ant-modal-body {
    max-height: calc(85vh - 150px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
      /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e3e3e6;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;

      border-radius: 5px;
    }
  }

.ant-upload.ant-upload-select-picture-card{height: 0!important;width: 140px!important;background-color: #009688!important;}
.ant-upload.ant-upload-select-picture-card > .ant-upload{color: #fff!important;}
.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label{text-align: right!important;margin-right: 10px!important;}
.ant-form-item{margin-bottom: 20px!important;}
.pagein{text-align: right!important;;margin-top: 30px!important;padding-bottom: 30px!important;}
</style>
