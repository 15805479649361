<template>
  <div class="version">
    <div class="queryTop">

       <a-button type="primary" style="margin-right: 10px;margin-bottom:30px;"  icon="plus" @click="AddConfigure()">添加弹窗</a-button>
       <span @mouseenter="conClick" @mouseleave="conleave">
         <a-icon type="question-circle" style="font-size:22px;cursor: pointer;color:#888;"  />
       </span>
      <div class="countryInt" v-if="cintrutFlag">
        该列表可查看、编辑所有弹窗信息。每添加一个弹窗，该列表就新增一行弹窗信息。
        <!-- <img src="../assets/img/2.png" alt="" > -->
      </div>
    </div>

    <!-- 列表内容 -->
   
       <a-table bordered :data-source="dataSource" :columns="columns" :pagination="false">
       <a slot="name" slot-scope="text">{{ text }}</a>
          <template slot="operatindex" slot-scope="text, record">
            <div class="editable-row-operations">
              <span class="operatTab">
                
                <a :disabled="editingKey !== ''" @click="() => editList(record.key)">编辑</a>
                <a :disabled="editingKey !== ''" @click="() => deleteConfigure(record.key)">删除</a>
              </span>
            </div>
          </template>

          <template slot="imgIndex" slot-scope="text,record">
            
            <img :src="record.img" alt="" style="width: 60%;height: 40px;">
             
          </template>
          <template slot="grouocodeIndex" slot-scope="text,record">
            
            <span class="operatTab" v-if="record.group_name=='active_popup'">活动弹窗</span>
             
          </template>
    </a-table>
   
     <!-- 分页 -->
    <div class="pagein">
        <a-pagination v-model="pages" :total="total" :page-size="rows" show-less-items
            :show-total="total => `共${total}条数据`" @change="onChangePage(pages)" />
    </div>

    <!-- 添加弹窗 -->
      <div class="addBox">
        <a-modal :visible="visible" title="添加弹窗" okText='确认' cancel-text="取消" @cancel="handleCancel" @ok="handleOk" width="800px" height="800px">
          <!--表单 并将表单的值绑定到this.from-->
          <a-form layout='vertical' :form="form" :label-col="{ span: 8}" :wrapper-col="{ span: 14 }">
            <!--每一项元素-->
            <a-form-item label='标题名称:'>
              <a-input placeholder="标题名称"  v-decorator="[ 'title', { rules: [{ required: true, message: '标题名称' }],  } ]" />
              <a-icon type="question-circle" @mouseenter="titleClick" @mouseleave="titleLeave" style="font-size:20px;cursor: pointer;color:#888;position: absolute;left: 108%;"  />
              

            </a-form-item>
            <a-form-item label='位置:'>
              <a-select v-model="info.location"  class="memberpx" >
                <a-select-option v-for="item in locatData" :value="item.value" :key="item.value">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label='设备:'>
              <a-select   class="memberpx" v-model="info.equipment">
                <a-select-option v-for="item in equipmentData" :value="item.value" :key="item.value">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label='跳转类型:'>
              <a-select   class="memberpx" v-model="info.jumpType">
                <a-select-option v-for="item in jumpData" :value="item.value" :key="item.value">
                  {{item.name}}
                </a-select-option>
              </a-select>
              <a-icon type="question-circle" @mouseenter="mealNameClick" @mouseleave="mealnameleave" style="font-size:20px;cursor: pointer;color:#888;position: absolute;left: 108%;"  />
            </a-form-item>
            <a-form-item label='是否可关闭:'>
              <a-select   class="memberpx" v-model="info.close">
                <a-select-option v-for="item in closeData" :value="item.value" :key="item.value">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label='内容'>
                    <a-input v-model="info.desc" type="textarea" />
            </a-form-item>
            <a-form-item label='跳转地址:'>
              <a-input placeholder="跳转地址"  v-model="info.jumpUrl"/>
              <a-icon type="question-circle" @mouseenter="urlClick" @mouseleave="urlleave" style="font-size:20px;cursor: pointer;color:#888;position: absolute;left: 108%;"  />
            </a-form-item>
            <!-- <a-form-item label='跳转标题:'>
              <a-input placeholder="跳转标题" v-model="info.jumpTitle" />
            </a-form-item> -->
           
            <a-form-item label='图片:'>
              <a-upload name="file" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" action="https://api.zhengxingjiufu.com/admin/upload_img" :before-upload="beforeUpload" @change="uploadImgChange">
               <div class="ant-upload-text">选择上传图片</div>
              </a-upload>
              <div class="uploadImgs" v-if="imageUrl">
                <img :src="imageUrl" alt="avatar" style="width:130px;height:80px;"/>
              </div>
            </a-form-item>

           
            <!-- <a-form-item label='开始时间:'>
              <a-date-picker :locale="locale" show-time placeholder="请选择开始时间" @change="startChange" @ok="startOk"  />
            </a-form-item>
            <a-form-item label='结束时间:'>
              <a-date-picker :locale="locale" show-time placeholder="请选择结束时间" @change="finishChange" @ok="finishOk" />
            </a-form-item> -->
            <!-- <a-form-item label='排序:'>
              <a-input placeholder="排序"  v-model="info.sort"/>
            </a-form-item> -->
            <a-form-item label='是否显示（状态）:'>
              <a-select   class="memberpx" v-model="info.showFlag">
                <a-select-option v-for="item in flagData" :value="item.value" :key="item.value">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
        </a-modal>
      </div>

    <!-- 编辑弹窗 -->
      <div class="edditBox">
        <a-modal :visible="visibleEdit" title="信息" okText='确认' cancel-text="取消" @cancel="editCancel" @ok="editOk" width="800px" height="800px">
          <!--表单 并将表单的值绑定到this.from-->
          <a-form layout='vertical' :form="formEdit" :label-col="{ span: 8}" :wrapper-col="{ span: 14 }">
            <!--每一项元素-->
            <a-form-item label='标题名称:'>
              <a-input placeholder="标题名称"  v-decorator="[ 'title', { rules: [{ required: true, message: '标题名称' }],  } ]" />
            </a-form-item>
            <a-form-item label='位置:'>
              <a-select v-model="info.location"  class="memberpx" >
                <a-select-option v-for="item in locatData" :value="item.value" :key="item.value">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label='设备:'>
              <a-select   class="memberpx" v-model="info.equipment">
                <a-select-option v-for="item in equipmentData" :value="item.value" :key="item.value">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label='跳转类型:'>
              <a-select   class="memberpx" v-model="info.jumpType">
                <a-select-option v-for="item in jumpData" :value="item.value" :key="item.value">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
             <a-form-item label='是否可关闭:'>
              <a-select class="memberpx" v-model="info.close">
                <a-select-option v-for="item in flagData" :value="item.value" :key="item.value">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label='内容'>
                <a-input v-model="info.desc" type="textarea" />
            </a-form-item>
            <a-form-item label='跳转地址:'>
              <a-input placeholder="跳转地址"  v-model="info.jumpUrl"/>
            </a-form-item>
            <!-- <a-form-item label='跳转标题:'>
              <a-input placeholder="跳转地址" v-model="info.jumpTitle" />
            </a-form-item>
             -->
            <a-form-item label='图片:'>
              <a-upload name="file" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" action="https://api.zhengxingjiufu.com/admin/upload_img" :before-upload="beforeUpload" @change="uploadImgChange">
               <div class="ant-upload-text">选择上传图片</div>
              </a-upload>
              <div class="uploadImgs" v-if="imageUrl">
                <img :src="imageUrl" alt="avatar" style="width:130px;height:80px;"/>
              </div>
            </a-form-item>

           
            <!-- <a-form-item label='开始时间:'>
              <a-date-picker :locale="locale" show-time placeholder="请选择开始时间" @change="startChange" @ok="startOk" :value="this.moment(info.startTime, dateFormat)"  />
            </a-form-item>
            <a-form-item label='结束时间:'>
              <a-date-picker :locale="locale" show-time placeholder="请选择结束时间" @change="finishChange" @ok="finishOk" :value="this.moment(info.endTime, dateFormat)" />
            </a-form-item> -->
            <!-- <a-form-item label='排序:'>
              <a-input placeholder="排序"  v-model="info.sort"/>
            </a-form-item> -->
            <a-form-item label='是否显示（状态）:'>
              <a-select   class="memberpx" v-model="info.status">
                <a-select-option v-for="item in flagData" :value="item.value" :key="item.value">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
        </a-modal>
      </div>
      <div class="countryInt areaInt" v-if="mealNameFlag" style="top: 88%;left: 55%;width: 300px;" >
            <p>app外部链接：从当前网站跳转到其他网站。</p>
            <p>app内部链接：在当前网站中，网页之间相互跳转。此处的内部链接如果为空，则默认跳转到套餐充值页面。</p>
      </div>

      <div class="countryInt areaInt" v-if="urlFlag" style="top: 95%;left: 55%;width: 300px;" >
            跳转地址：点击该banner后跳转到达的页面即为跳转地址。
      </div>

      <div class="countryInt areaInt" v-if="mealTitleFlag" style="top: 25%;left: 55%;width: 350px;" >
  
            <p>若配置弹窗： </p>
            <p>（1）标题名称为：温馨提示</p>
            <p> （2）设备为：iOS</p>
            <p>（3）位置为：首页banner</p>
            <p>（4） 内容为：加速前请先...不支持访问！</p>
            <p>（5） 显示为：是</p>
            <p>则相应的显示如下：</p>
            <div>
              <img src="../../assets/img/pop1.png" alt="" style="display: inline-block;width:240px;height: 440px;margin-right: 20px;">
              
            </div>
      </div>

  </div>
</template>
<script>
function getBase64(img, callback) {

  const reader = new FileReader();

  reader.addEventListener('load', () => callback(reader.result));

  reader.readAsDataURL(img);

}
import common from '@/assets/js/Common'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import moment from 'moment';

import API from '@/assets/js/Api'
export default {

  data() {
    this.dateFormat = 'YYYY/MM/DD HH:mm:ss'
    return {
      locale,
      cintrutFlag:false,
      mealNameFlag: false,
      urlFlag: false,
      mealTitleFlag: false,

      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          width: '4%',
          align: 'center',
          scopedSlots: { customRender: 'ID' },

        },
        {
          title: '缩略图',
          dataIndex: 'img',
          width: '10%',
          align: 'center',
           scopedSlots: { customRender: 'imgIndex' },


        },
        {
          title: '标题',
          dataIndex: 'title',
          width: '10%',
          align: 'center',
          scopedSlots: { customRender: '标题' },

        },
        {
          title: '位置',
          dataIndex: 'group_name',
          width: '10%',
          align: 'center',
          scopedSlots: { customRender: 'grouocodeIndex' },

        },
        {
          title: '设备',
          dataIndex: 'platform',
          width: '10%',
          align: 'center',
          scopedSlots: { customRender: '设备' },
        },
        
        {
          title: '显示',
          dataIndex: 'status',
          width: '5%',
          align: 'center',
          scopedSlots: { customRender: '显示' },
        },
        // {
        //   title: '开始时间',
        //   dataIndex: 'start',
        //   width: '10%',
        //   align: 'center',
        //   scopedSlots: { customRender: '创建时间' },
        // },
        // {
        //   title: '结束时间',
        //   dataIndex: 'end',
        //   width: '10%',
        //   align: 'center',
        //   scopedSlots: { customRender: '结束时间' },
        // },
        {
          title: '操作人',
          dataIndex: 'admin',
          width: '5%',
          align: 'center',
          scopedSlots: { customRender: '操作人' },
        },
        {
          title: '操作',
          dataIndex: 'index',
          align: 'center',
          width: '8%',
          scopedSlots: { customRender: 'operatindex' },
        },
      ],
      dataSource: [],
      equipmentData:[
        {name: 'Android', value: 'Android'},
        {name: 'IOS', value: 'IOS'},
        {name: 'PC', value: 'PC'},

      ],
      jumpData:[
        {name: 'app内部链接', value: 'app内部链接'},
        {name: 'app外部链接', value: 'app外部链接'},
      ],
      locatData: [
        {name: '活动弹窗', value: '活动弹窗'},
       
        
      ],
      flagData:[
        {name:'是', value:'是'},
        {name:'否', value:'否'},
      ],
      closeData:[
        {name:'是', value:'是'},
        {name:'否', value:'否'},
      ],
      
      editingKey: '',
      visible: false,   //添加列表开关
      visibleEdit: false,//编辑
      imageUrl: '',  
      editUrl:'',
      bannerId:'1',
      info:{
        id:'0',
        title:'',
        jumpUrl:'',
        jumpTitle:'',
        status:'是',
        equipment: 'Android',
        close: '是',
        location:'活动弹窗',
        jumpType: "app内部链接",
        startTime: '2021-04-21 03:03:30',
        endTime: '2021-04-21 03:03:30',
        sort:'0',
        desc:''
      },
      form: this.$form.createForm(this),
      formEdit: this.$form.createForm(this),
      pages:1,
      rows:10,
      total:0,
      imgSrc:''
    };
  },
  methods: {
    moment,
    conClick(){
      this.cintrutFlag = true;
    },
    conleave(){
      this.cintrutFlag = false;
    },
    urlClick(){
        this.urlFlag = true;
    },
    urlleave(){
        this.urlFlag = false;
    },
    mealNameClick(){
        this.mealNameFlag = true;
    },
    mealnameleave(){
      this.mealNameFlag = false
    },
    titleClick(){
      this.mealTitleFlag = true;
    },
    titleLeave(){
      this.mealTitleFlag = false;

    },
    onCellChange(key, dataIndex, value) {
      const dataSource = [...this.dataSource];
      const target = dataSource.find(item => item.key === key);
      if (target) {
        target[dataIndex] = value;
        this.dataSource = dataSource;
      }
    },
    onDelete(key) {
      const dataSource = [...this.dataSource];
      this.dataSource = dataSource.filter(item => item.key !== key);
    },
   //关闭编辑弹窗

    editCancel() {

        this.visibleEdit = false;

    },
    // 选择开始时间
    startChange(value, dateString) {
      
      this.info.startTime = dateString;
    },
    // 点击开始时间确认按钮
    startOk(value) {
      console.log('onOk: ', value);
      console.log(this.info.startTime,   value)
    },
    // 选择结束时间
    finishChange(value, dateString) {
      console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString);
      this.info.endTime = dateString;
    },
    // 点击结束时间确认按钮
    finishOk(value) {
      console.log('onOk: ', value);
      console.log(this.info.endTime,   value)
    },
    //清空搜索
    clearSeach(){},
     //分页查询国家设置
    seach(){

       let opts = {

          url: API.popList,
          data: {
            page: this.pages
          },       
        }

        common.query(opts).then(res=>{
          let arr = []
          let result = res.list;
          this.total = res.total
          result.forEach(function(v,i){
            if(v.link_type == 1){
              v.link_type='app内部链接'
            }
            if(v.link_type == 2){
              v.link_type='app外部链接'
            }
            if(v.status=='2'){
              v.status='否'
            }
            if(v.status=='1'){
              v.status='是'
            }
           
            arr.push({key: v.id, id: v.id, title: v.name,img:v.img, group_name: v.code, platform: v.platform, status:v.status, start:v.start,end: v.end, admin: v.admin});

          })

           this.dataSource = arr;
      },res=>{   
            this.$message.error(res.remark);

      });
    },

    onChangePage(page){
      this.pages = page;
      console.log(this.pages)
      this.seach();
    },

    //关闭添加弹窗

    handleCancel() {

        this.visible = false;

    },

     //关闭编辑弹窗

    editCancel() {

        this.visibleEdit = false;

    },

    //点击添加确认添加
    handleOk() {
        const form = this.form;
        let opts = {};
        let plat = this.info.equipment;
         var type = this.info.jumpType;
         var st = this.info.status;
         var locat = this.info.location;
         var close = this.info.close;
        if(plat == 'Android'){
          plat = 'android'
        }
        if(plat == 'IOS'){
          plat = 'iphone'
        }
        if(plat == 'PC'){
          plat = 'pc'
        }
       
          if(type == 'app内部链接'){
            type = '1'
          }
          if(type == 'app外部链接'){
            type = '2'
          }
          if(st=='是'){
            st='1'
          }
          if(st=='否'){
            st='2'
          }
          if(locat == '套餐页'){
            locat ='pay_banner'
          }
          if(locat == '首页Banner'){
            locat ='banner'
          }
          if(close == '是'){
            close = '1'
          }
          if(close == '否'){
            close = '0'
          }
        console.log(this.info,this.imgSrc)
        
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            console.log('form 表单内容: ', values);
                         
            opts = {
                url: API.popAdd,
                data: {
                  id:'',                
                  img: this.imgSrc,
                  status: st,
                  name: values.title,
                  link_type: type,
                  link_url: this.info.jumpUrl,
                  link_title: this.info.jumpTitle,
                  code: 'active_popup',
                  platform: plat,
                  start: this.info.startTime,
                  end: this.info.endTime,
                  sort: this.info.sort,
                  content: this.info.desc,
                  is_closed: close
                  
                },       
              }
        });
        
        common.query(opts).then(res=>{
          this.$message.success('添加成功');
          form.resetFields(); 
          this.visible = false;

         this.seach()
      },res=>{   
            this.$message.error(res.remark);

      });
    },

    // 编辑确认按钮
    editOk(){
        const form = this.formEdit;
        
         var st = this.info.status;
         let plat = this.info.equipment;
         var type = this.info.jumpType;
         var st = this.info.status;
         var locat = this.info.location;
         var close = this.info.close;
          if(type == 'app内部链接'){
            type = '1'
          }
          if(type == 'app外部链接'){
            type = '2'
          }
          if(st=='是'){
            st='1'
          }
          if(st=='否'){
            st='2'
          }
           if(plat == 'IOS'){
            plat = 'iphone'
          }
          if(plat == 'Android'){
            plat = 'android'
          }
          if(plat == 'PC'){
            plat = 'pc'
          }
          if(locat == '套餐页'){
            locat ='pay_banner'
          }
          if(locat == '首页Banner'){
            locat ='banner'
          }
          if(close == '是'){
            close = '1'
          }
          if(close == '否'){
            close = '0'
          }

            let opts = {}
            form.validateFields((err, values) => {
                if (err) {
                    return;
                }
                console.log('form 表单内容: ', values);
                form.resetFields();
                
                opts = {
                    url: API.popAdd,
                    data: {
                      id: this.bannerId,
                      img: this.imgSrc,
                      status: st,
                      name: values.title,
                      link_type: type,
                      link_url: this.info.jumpUrl,
                      link_title: this.info.jumpTitle,
                      code: 'active_popup',
                      platform: plat,
                      start: this.info.startTime,
                      end: this.info.endTime,
                      sort: this.info.sort,
                      content: this.info.desc,
                      is_closed: close
                     
                    },       
                  }
            });
            
            common.query(opts).then(res=>{
              this.$message.success('修改成功');
              this.visibleEdit = false;    
              // this.$message.info('添加成功');
            this.seach()
          },res=>{   
            this.$message.error(res.remark);
          });
    },

    // 查询编辑指定banner内容
    editList(key){
     
      this.visibleEdit = true;
      var id = key;
      this.bannerId = id;
       let opts = {
              url: API.popEdit,
              data: {
                id: key
              },       
        }
        
        common.query(opts).then(res=>{
         this.imgSrc = res.img
          var type = res.link_type;
          var plat = res.platform;
          var closed = res.is_closed

          if(type == '1'){
            type = 'app内部链接'
          }
          if(type == '2'){
            type = 'app外部链接'
          }
          if(plat == 'iphone'){
            plat = 'IOS'
          }
          if(plat == 'android'){
            plat = 'Android'
          }
          if(plat == 'pc'){
            plat = 'PC'
          }
          if(res.status == '2'){
            res.status = '否'
          }
          if(res.status == '1'){
            res.status = '是'
          }
          if(closed== '0'){
            closed = '否'
          }
          if(closed == '1'){
            closed = '是'
          }
          console.log(closed)
          this.formEdit.setFieldsValue({
             title: res.name
             

          })
          console.log(res.status)
         this.info={
           location: '活动弹窗',
           equipment: plat,
           jumpType: type,
           jumpUrl:res.link_url,
           jumpTitle: res.link_title,
           startTime: res.start,
           endTime: res.end,
           sort: res.sort,
           status: res.status,
           close: closed,
           desc: res.content

           
         }
         this.imageUrl= res.img;
      },res=>{   
            this.$message.error(res.remark);
        
      });
    },

    // 删除
    
    deleteConfigure(key){
      let _this = this;
      let opts = {
            url: API.popDelete,
            data: {
              id: key
            },       
      }
      this.$confirm({
        title: '确定删除？',
        content: '',
        okText: '是的',
        okType: '',
        cancelText: '取消',
        onOk() {
          common.query(opts).then(res=>{
          
            _this.$message.success('删除成功');
            _this.seach();
          },res=>{   
            _this.$message.error(res.remark);
          });
        },
        onCancel() {
          
        },
      });
    },


  // 上传图片前处理
    beforeUpload(file) {

        console.log(file)

        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

        if (!isJpgOrPng) {

          this.$message.error('You can only upload JPG file!');

        }

        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isLt2M) {

          this.$message.error('Image must smaller than 2MB!');

        }

        return isJpgOrPng && isLt2M;

    },

     //上传图片

    uploadImgChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      console.log(info)
      if (info.file.status === 'done') {
        console.log(info.file.response.data.path_url)
        this.imgSrc = info.file.response.data.path_url;
        getBase64(info.file.originFileObj, imageUrl => {
          this.imageUrl = imageUrl;       
          console.log(imageUrl)   
          this.editUrl = imageUrl
          this.loading = false;

        });

      }

    },

     // 点击添加弹窗按钮

      AddConfigure(key) {

        this.visible= true;
        this.info={
          title:'',
          jumpUrl:'',
          jumpTitle:'',
          status:'是',
          equipment: 'Android',
          close: '是',
          location:'活动弹窗',
          jumpType: "app内部链接",
          sort:'0',
          desc:'',
          imageUrl:''
        }
      },

},

  mounted(){

     this.seach()
     

  },
  beforeCreate() {
          //创建表单
    this.form = this.$form.createForm(this, { name: 'form_in_modal' });
    this.formEdit = this.$form.createForm(this, { name: 'form_in_modals' });
  },
};
</script>
<style lang="less">

.version{padding-top: 20px;

  .memberpx{width: 14%!important;margin-right: 20px;}

  .clear{margin-top: 20px;}

  .queryContent{margin-top: 30px;}

  .operatTab a{padding: 2px 10px;background: #1890ff;color: #fff;border-radius: 5px;display: inline-block;margin-right: 8px;}

}


</style>
