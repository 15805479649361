<template>
  <div class="welcome">
    <h1>This is an welcome page</h1>
    <div class="content">

      <img src="../assets/img/1.png" style="text-align: center;" alt="" />
    </div>
    
  </div>
</template>
<script>


export default {
  name: 'Welcome',
  components: {
  }
}
</script>
<style scoped lang="less">
.welcome{padding-top: 20px;
  .content{text-align: center;
    img{display:inline-block;}
  }
}
  
</style>