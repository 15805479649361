import Vue from 'vue'
import Vuex from 'vuex'
import {getAction} from "@/utils/request"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userName:sessionStorage.getItem('userName')||'',
    token:sessionStorage.getItem('token')||'',
    menuLists:JSON.parse(sessionStorage.getItem('menuLists'))|| [],
  },
  mutations: {
    SETUSERNAE(state,value){
      state.userName=value
    },
    SETTOKEN(state,value){
      state.token=value
    },
    SETMENULISTS(state,arr){
      state.menuLists=arr
    }
  },
  actions: {
    setTokenAction({commit},loginMessage){
      return new Promise((resolve,reject)=>{      
        getAction('./data/user.json').then(res=>{
          console.log(res)
          commit('SETTOKEN',res.data.token)
          sessionStorage.setItem('token',res.data.token)
          commit('SETMENULISTS',res.data.authMenus)
          sessionStorage.setItem('menuLists',JSON.stringify(res.data.authMenus))
          commit('SETUSERNAE',res.data.userName)
          sessionStorage.setItem('userName',res.data.userName)
          resolve()
        })

      })
      
    }
  },
  modules: {
  }
})
