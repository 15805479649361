import Welcome from '@/views/Welcome.vue'
import Member from '@/views/Member.vue'
import UpdatVersion from '@/views/updatVersion.vue'
import Order from '@/views/Order.vue'
import Meal from '@/views/Meal.vue'
import Version from '@/views/systemConfiguration/Version.vue'
import ChartLint from '@/views/dataStatist/chartLint.vue'
import CityLine from '@/views/CityLine.vue'
import OrderStatist from '@/views/rechargeStatist/orderStatist.vue'
import OrderTimeStatist from '@/views/rechargeStatist/orderTimeStatist.vue'
import PopBox from '@/views/operatManagement/popBox.vue'
import BannerConfig from '@/views/operatManagement/bannerConfig.vue'
export default {
    "/layout/welcome": {
      path: 'welcome',
      name: 'Welcome',
      component: Welcome,
      meta: {
        title: '欢迎页'
      }
    },
    "/layout/member": {
      path: 'member',
      name: 'Member',
      component: Member,
      meta: {
        title: '会员列表'
      }
    },
    "/layout/cityLine": {
      path: 'cityLine',
      name: 'cityLine',
      component: CityLine,
      meta: {
        title: '线路配置'
      }
    },
    "/layout/updatVersion": {
      path: 'updatVersion',
      name: 'UpdatVersion',
      component: UpdatVersion,
      meta: {
        title: '国家配置'
      }
    },
    "/layout/order": {
      path: 'order',
      name: 'Order',
      component: Order,
      meta: {
        title: '订单列表'
      }
    },
    "/layout/meal": {
      path: 'meal',
      name: 'Meal',
      component: Meal,
      meta: {
        title: '套餐列表'
      }
    },
    "/layout/version": {
      path: 'version',
      name: 'Version',
      component: Version,
      meta: {
        title: '版本管理'
      }
    },
    "/layout/chartLint": {
      path: 'chartLint',
      name: 'ChartLint',
      component: ChartLint,
      meta: {
        title: '每日数据'
      }
    },
    "/layout/orderStatist": {
      path: 'orderStatist',
      name: 'OrderStatist',
      component: OrderStatist,
      meta: {
        title: '订单统计'
      }
    },
    "/layout/orderTimeStatist": {
      path: 'orderTimeStatist',
      name: 'OrderTimeStatist',
      component: OrderTimeStatist,
      meta: {
        title: '订单分时段统计'
      }
    },
    "/layout/popBox": {
      path: 'popBox',
      name: 'PopBox',
      component: PopBox,
      meta: {
        title: '弹窗'
      }
    },
    "/layout/bannerConfig": {
      path: 'bannerConfig',
      name: 'BannerConfig',
      component: BannerConfig,
      meta: {
        title: 'banner图配置'
      }
    },

  }