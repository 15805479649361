
let Test = 'https://api.zhengxingjiufu.com';
let Production = 'https://api.zhengxingjiufu.com';
let hostName = location.host.indexOf('域名') != -1 ? Production : Test;

let API = {

	countryList: '/admin/country_list', 	// 国家配置列表
	AddCountry: '/admin/country_update', 	// 添加国家配置列表
	queryEditCountry: '/admin/country_form',   //编辑指定国家
	delectCountry: '/admin/country_delete',   //删除国家
	lineList: '/admin/lines_index',//线路列表查新
	queryCountry:'/admin/get_country_lists', //查询国家列表
	addLine:'/admin/update_line',   //添加线路
	editLine:'/admin/line_form',     //编辑线路
	deleteLine: '/admin/delete_line',  //删除路线
	memberList: '/admin/user_list',  //会员列表
	memberEdit: '/admin/user_form', //编辑会员
	updataMember: '/admin/update_user',  //更新会员
	mealList: '/admin/package_index', //套餐列表
	orderList: '/admin/order_index',    //订单列表
	addMeal: '/admin/update_package',  //添加套餐
	dayList: '/admin/every_day_data',  //每日数据
	bannerList: '/admin/advert_index',   //banner列表
	bannerAdd: '/admin/advert_update',   //添加banner配置
	bannerEdit: '/admin/advert_form',     //获取编辑banner内容
	bannedrDelete: '/admin/advert_delete',  //删除banner
	popList: '/admin/popup_index',   //弹窗列表
	popAdd: '/admin/popup_update',    //添加弹窗
	popEdit: '/admin/popup_form',   //编辑弹窗内容
	popDelete: '/admin/popup_delete',  //删除弹窗内容
	mealInfo: '/admin/package_form',//查询指定套餐信息
	delectMeal: '/admin/delete_package',  //删除套餐
	segmentTimeCount:'/admin/order_dayparting_status',  //计时分段统计
	segmentCount: '/admin/order_status',   //计时统计
	memberContry: '/admin/get_user_country',   //会员页国家列表
	versionList: '/admin/version_list',// 系统配置==版本管理列表
	versionAdd: '/admin/version_update',// 系统配置==版本管理添加、更新,
	versionDelete: '/admin/version_delete',  // 系统配置==版本管理删除
	versionEdit: '/admin/version_form',   //系统配置==版本管理编辑




}

Object.keys(API).forEach(key => {
	API[key] = hostName + API[key];
})

export default API
