<template>
  <div class="menus">
    <a-menu
      v-model="selectedKeys"
      :openKeys.sync="openKeys"
      mode="inline"
      theme="dark"
      :inline-collapsed="collapsed"
    >
      <!-- 一级菜单 -->
      <template v-for="item in menuLists">
        <template v-if="item.children && item.children.length > 0">
          <a-sub-menu :key="item.id">
            <span slot="title"
              ><a-icon
                :type="item.icon === undefined ? 'appstore' : item.icon"
              /><span>{{ item.menuName }}</span></span
            >
            <!-- 二级菜单 -->
            <template v-for="child in item.children">
              <template v-if="child.children && child.children.length > 0">
                <a-sub-menu :key="child.id">
                  <span slot="title"
                    ><a-icon
                      :type="item.icon === undefined ? 'appstore' : item.icon"
                    /><span>{{ child.menuName }}</span></span
                  >
                  <!-- 三级菜单 -->
                  <!-- <template v-for="grandson in child.children">
                    <a-menu-item :key="grandson.path">
                      <router-link :to="{ path: child.path }">
                        {{ grandson.menuName }}
                      </router-link>
                    </a-menu-item>
                  </template> -->
                </a-sub-menu>
              </template>
              <template v-else>
                <a-menu-item :key="child.path">
                  <router-link :to="{ path: child.path }">
                    {{ child.menuName }}
                  </router-link>
                </a-menu-item>
              </template>
            </template>
          </a-sub-menu>
        </template>
        <template v-else>
          <a-menu-item :key="item.path">
            <router-link :to="{ path: item.path }">
              <a-icon
                :type="item.icon === undefined ? 'appstore' : item.icon"
              />
              <span>{{ item.menuName }}</span>
            </router-link>
          </a-menu-item>
        </template>
      </template>
    </a-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["activeKey"],
  data() {
    return {
      collapsed: false,
      selectedKeys: ["/welcome"],
      openKeys: [],
    };
  },
  computed: {
    
    ...mapState(["menuLists"]),
  },
  watch: {
    activeKey(newVal, oldVal) {
      if (this.$route.path !== newVal) {
        this.goPath(this.menuLists, newVal);
      }
    },
    $route: {
      handler(newRouter, oldRouter) {
        this.selectedKeys = [];
        this.selectedKeys.push(newRouter.path);
        this.openKeys = [];
        this.openParentNode(this.menuLists, newRouter.path);
      },
      immediate: true,
    },
  },
  methods: {
    openParentNode(data, path) {
      for (let item of data) {
        if (item.path === path && item.parentId !== 0) {
          this.openKeys.push(item.parentId);
          return true;
        }
        if (item.children && item.children.length > 0) {
          if (this.openParentNode(item.children, path)) {
            return true;
          }
        }
      }
    },
    goPath(data, key) {
      for (let item of data) {
        if (item.path === key) {
          this.$router.push({ path: item.path });
          return true;
        }
        if (item.children && item.children.length > 0) {
          if (this.goPath(item.children, key)) {
            return true;
          }
        }
      }
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>
<style scoped>
</style>