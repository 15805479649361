import axios from "axios";
import {stringify} from 'qs'
//get
export function getAction(url, parameter) {
    return axios({
      url,
      method: 'get',
      params: parameter,
      paramsSerializer: params => {
        return stringify(params, { indices: false })
      }
    })
  }
  