import Vue from 'vue'
import VueRouter from 'vue-router'
import childRouters from './router.config'
import store from '../store'
import Layout from '@/components/Layout.vue'
import Login from '@/views/Login.vue'
import NoFound from '@/views/404.vue'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const constantRouterMap = [
  {
    path: '/',
    redirect: "/login"
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '登录'
    }
  }, {
    path: '/layout',
    name: 'Layout',
    component: Layout,
    redirect: "/layout/welcome",
    children: []
  }

]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: constantRouterMap
})
const menusPath = []
const getMenusPath = (list) => {
  for (let item of list) {
    if (item.children && item.children.length > 0) {
      getMenusPath(item.children)
    }
    if (item.path) {
      menusPath.push(item.path)
    }
  }
}
const genRoute = () => {
  getMenusPath(store.state.menuLists)
  for (let item of menusPath) {
    if (childRouters[item]) {
      router.addRoute("Layout", childRouters[item])
    }
  }
  router.addRoute({
    path: '*',
    name: 'Err',
    component: NoFound,
    meta: {
      title: '404'
    }
  })
}
router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    next()
  } else {
    if (store.state.token !== "") {
      if (!router.getRoutes().find(item => item.path === to.path)) {
        genRoute()
        next({ ...to, replace: true })
      } else {
        next()
      }
    } else {
      next({ path: '/login' })
    }
  }
})
router.afterEach((to, from) => {
  if (to.meta.title) {
    document.title = to.meta.title;    //在路由里面写入的meta里面的title字段
  }
})

export default router
